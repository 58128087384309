import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_USER, DefaultUserRole, UPDATE_USER, UserGender } from '~saga'

import { UserProfile } from '@teen-care/core'
import dayjs from 'dayjs'
import { useAppDispatch } from '~core'
import { useState } from 'react'

function parseDate(dateString: string) {
  const parts = dateString?.split('-') || []
  if (parts.length !== 3) {
    return new Date(2024, 0, 1).valueOf()
  }
  const day = parseInt(parts[2], 10)
  const month = parseInt(parts[1], 10) - 1
  const year = parseInt(parts[0], 10)
  const isValidDate = day > 0 && day <= 31 && month >= 0 && month <= 11 && year > 0
  if (!isValidDate) {
    return new Date(2024, 0, 1)
  }
  return new Date(year, month, day).valueOf()
}

export function CreateUser({
  content,
  item,
  textOnly,
  title,
}: {
  content: string
  item?: UserProfile
  textOnly?: boolean
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const [showCreate, setShowCreate] = useState<boolean>(false)

  const [dob, setDob] = useState<string>(
    item?.dob ? dayjs(new Date(item?.dob || '')).format('YYYY-MM-DD') : '',
  )

  const [gender, setGender] = useState<UserGender>(item?.gender || UserGender.NULL)
  const [name, setName] = useState<string>(item?.name || '')
  const [password, setPassword] = useState<string>('')
  const [phone, setPhone] = useState<string>(item?.phone || '')
  const [username, setUsername] = useState<string>(item?.username || '')
  const [userRoleName, setUserRoleName] = useState<DefaultUserRole>(DefaultUserRole?.DEFAULT)

  const clearData = () => {
    setShowCreate(false)
    setDob('')
    setGender(UserGender.NULL)
    setName('')
    setPassword('')
    setPhone('')
    setUsername('')
    setUserRoleName(DefaultUserRole?.DEFAULT)
  }

  const handleShow = () => {
    setShowCreate(true)
    isUpdate && setDob(item?.dob ? dayjs(new Date(item?.dob || '')).format('YYYY-MM-DD') : '')
    isUpdate && setGender(item?.gender || UserGender.NULL)
    isUpdate && setName(item?.name || '')
    isUpdate && setPassword('')
    isUpdate && setPhone(item?.phone || '')
    isUpdate && setUsername(item?.username || '')
    isUpdate && setUserRoleName(DefaultUserRole?.DEFAULT)
  }

  function createUser() {
    dispatch({
      onSuccess: clearData,
      payload: isUpdate
        ? {
            _id: item?._id,
            dob: parseDate(dob)?.valueOf(),
            gender,
            name,
            password,
            phone,
            username,
          }
        : { dob: parseDate(dob)?.valueOf(), gender, name, password, phone, userRoleName, username },
      type: isUpdate ? UPDATE_USER : CREATE_USER,
    })
  }

  return (
    <>
      {textOnly ? (
        <div onClick={handleShow}>{title}</div>
      ) : (
        <Button className='m-1' variant='dark' onClick={handleShow}>
          {title}
        </Button>
      )}

      <Modal show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Tên</Form.Label>
            <Form.Control
              placeholder='Nhập tên người dùng'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Ngày sinh</Form.Label>
            <Form.Control
              max={dayjs(new Date()).format('YYYY-MM-DD')}
              type='date'
              value={dob}
              onChange={(e: any) => setDob(e?.target?.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Giới tính</Form.Label>
            <Form.Select onChange={(e: any) => setGender(e.target.value)}>
              <option>
                {gender === UserGender.MALE
                  ? 'Nam'
                  : gender === UserGender.FEMALE
                    ? 'Nữ'
                    : 'Chọn giới tính'}
              </option>
              <option value={UserGender.MALE}>Nam</option>
              <option value={UserGender.FEMALE}>Nữ</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>SĐT</Form.Label>
            <Form.Control
              placeholder='Nhập số điện thoại'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Username</Form.Label>
            <Form.Control
              placeholder='Nhập username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Mật khẩu</Form.Label>
            <Form.Control
              placeholder='Nhập mật khẩu'
              security='true'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createUser}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
