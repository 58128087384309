import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Lesson } from '@teen-care/core'
import type { RootState } from '~core'

export interface LessonState {
  currentPage: number
  lessons: Lesson[]
  loading: boolean
  totalPages: number
}

const initialState: LessonState = {
  currentPage: 1,
  lessons: [],
  loading: false,
  totalPages: 1,
} as LessonState

const lessonSlice = createSlice({
  initialState,
  name: 'lesson',
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLesson: (state, action: PayloadAction<Lesson>) => {
      state.lessons = state.lessons.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setLessons: (state, action: PayloadAction<Lesson[]>) => {
      state.lessons = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const lessonAction = lessonSlice.actions

export const lessonState = (state: RootState) => state.lesson

export const lessonReducer = lessonSlice.reducer
