import {
  commentReducer,
  postHistoryReducer,
  postReducer,
  topicReducer,
  userBadgeReducer,
  userProfileReducer,
  userRankingReducer,
  userReducer,
  userScoreReducer,
} from '~redux'

import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
  comment: commentReducer,
  post: postReducer,
  postHistory: postHistoryReducer,
  topic: topicReducer,
  user: userReducer,
  userBadge: userBadgeReducer,
  userProfile: userProfileReducer,
  userRanking: userRankingReducer,
  userScore: userScoreReducer,
})
