import '~assets/style/Post.css'

import { DELETE_COURSE, LIMIT_POST_ITEM } from '~saga'
import { getAssetsUrl, useAppDispatch, useAppSelector } from '~core'

import { Button } from 'react-bootstrap'
import { Course } from '@teen-care/core'
import { courseState } from '~redux'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export function CourseItem({ index, item }: { index: number; item: Course }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(courseState) || {}
  const navigate = useNavigate()
  const { courseDescription, courseName, createdAt, thumbnail } = item || {}

  function gotoLesson() {
    navigate(`/learn/lesson/${item._id}`)
  }

  function deleteItem(e: React.MouseEvent) {
    e.stopPropagation()
    dispatch({
      payload: { courseId: item._id },
      type: DELETE_COURSE,
    })
  }

  return (
    <tr onClick={gotoLesson}>
      <td className='align-middle'>
        <span className='fw-normal'>{(currentPage - 1) * LIMIT_POST_ITEM + index + 1}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{courseName}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{courseDescription}</span>
      </td>
      <td className='align-middle'>
        <img
          alt={thumbnail[0]}
          src={getAssetsUrl(thumbnail[0])}
          style={{ height: 30, marginRight: 5, width: 30 }}
        />
      </td>

      <td className='align-middle'>
        <span className='fw-normal'>
          {createdAt ? dayjs(createdAt).format('HH:mm DD/MM/YYYY') : '...'}
        </span>
      </td>
      <td className='align-middle'>
        <Button variant='danger' onClick={deleteItem}>
          Xóa
        </Button>
      </td>
    </tr>
  )
}
