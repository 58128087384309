import { apiPost, apiPostFormData } from '~core'
import { forumTopicAction, forumTopicState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { ForumTopic } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'

export const GET_FORUM_TOPICS = 'GET_FORUM_TOPICS'
export const LOAD_MORE_FORUM_TOPICS = 'LOAD_MORE_FORUM_TOPICS'
export const GET_FORUM_TOPIC = 'GET_FORUM_TOPIC'
export const CREATE_FORUM_TOPIC = 'CREATE_FORUM_TOPIC'
export const UPDATE_FORUM_TOPIC = 'UPDATE_FORUM_TOPIC'
export const REMOVE_FORUM_TOPIC = 'REMOVE_FORUM_TOPIC'

export const LIMIT_TOPIC_ITEM = 0

const forumAdminRouteName = {
  route: '/forum-admin',
  topicCreate: '/create-topic',
  topicGet: '/get-topic',
  topicGets: '/get-topics',
  topicRemove: '/remove-topic',
  topicUpdate: '/update-topic',
}

interface CreateTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    hashTag: string
    media?: any
    roleIds?: string[]
    title: string
  }
}
function* createTopic({ onFailure, onSuccess, payload }: CreateTopicParams) {
  try {
    const { hashTag, media, roleIds, title } = payload || {}
    const params = new FormData()
    params.append('hashTag', hashTag)
    params.append('title', title)
    roleIds && roleIds?.length && params.append('roleIds', JSON.stringify(roleIds))
    media && params.append('media', media)

    yield put(forumTopicAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicCreate}`,
    })
    const { forumTopics } = yield select(forumTopicState)
    yield put(
      forumTopicAction.setTopics([
        response?.data?.result?.forumTopic as ForumTopic,
        ...forumTopics,
      ] as ForumTopic[]),
    )
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopic)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    hashTag: string
    media?: any
    roleIds?: string[]
    title: string
  }
}
function* updateTopic({ onFailure, onSuccess, payload }: UpdateTopicParams) {
  try {
    const { _id, hashTag, media, roleIds, title } = payload || {}
    const params = new FormData()
    params.append('_id', _id)
    params.append('hashTag', hashTag)
    params.append('title', title)
    roleIds && roleIds?.length && params.append('roleIds', JSON.stringify(roleIds))
    media && params.append('media', media)
    yield put(forumTopicAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicUpdate}`,
    })
    yield put(forumTopicAction.setTopic(response?.data?.result?.forumTopic as ForumTopic))
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopic)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeTopic({ onFailure, onSuccess, payload }: RemoveTopicParams) {
  try {
    yield put(forumTopicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicRemove}`,
    })
    yield put(forumTopicAction.removeTopic(response?.data?.result?.forumTopic?._id))
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopic)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetTopicsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getTopics({ onFailure, onSuccess, payload }: GetTopicsParams) {
  try {
    yield put(forumTopicAction.setLoading(true))
    yield put(forumTopicAction.setCurrentPage(1))
    yield put(forumTopicAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_TOPIC_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGets}`,
    })
    yield put(forumTopicAction.setTopics(response?.data?.result?.forumTopics as ForumTopic[]))
    yield put(forumTopicAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(forumTopicAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopics)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreTopics({ onFailure, onSuccess, payload }: GetTopicsParams) {
  try {
    const { currentPage, totalPages } = yield select(forumTopicState)
    yield put(forumTopicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_TOPIC_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGets}`,
    })
    yield put(forumTopicAction.setTopics(response?.data?.result?.forumTopics as ForumTopic[]))
    yield put(forumTopicAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(forumTopicAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopics)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getTopic({ onFailure, onSuccess, payload }: GetTopicParams) {
  try {
    yield put(forumTopicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGet}`,
    })
    yield put(forumTopicAction.setTopic(response?.data?.result?.forumTopic as ForumTopic))
    yield put(forumTopicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumTopic)
  } catch (e: any) {
    yield put(forumTopicAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* forumTopicSaga() {
  yield takeEvery(GET_FORUM_TOPIC, getTopic)
  yield takeEvery(GET_FORUM_TOPICS, getTopics)
  yield takeEvery(LOAD_MORE_FORUM_TOPICS, loadMoreTopics)
  yield takeEvery(CREATE_FORUM_TOPIC, createTopic)
  yield takeEvery(UPDATE_FORUM_TOPIC, updateTopic)
  yield takeEvery(REMOVE_FORUM_TOPIC, removeTopic)
}
