import { useAppDispatch, useAppSelector } from '~core'

import { Form } from 'react-bootstrap'
import { Post } from '@teen-care/core'
import { VOTE_POST } from '~saga'
import { __ } from '~localization'
import numeral from 'numeral'
import { userProfileState } from '~redux'

export function PollPost(props: Post) {
  const { _id, isMultiAnswer, poll } = props || {}
  const { userProfile } = useAppSelector(userProfileState) || {}
  const dispatch = useAppDispatch()

  const totalVote = poll?.reduce((total, e) => total + (e.vote?.length || 0), 0)
  const hasVote = poll?.some((e) => {
    return e.vote?.findIndex((voter) => voter.toString() === userProfile?._id?.toString()) !== -1
  })

  function votePost(pollId: string) {
    dispatch({
      payload: { pollId, postId: _id },
      type: VOTE_POST,
    })
  }

  return (
    <div>
      {poll
        ? poll.map((item, index) => {
            const total = Number(
              ((item.vote?.length / (totalVote === 0 ? 1 : totalVote)) * 100).toFixed(2),
            )
            const isVote =
              item.vote?.findIndex((voter) => voter.toString() === userProfile?._id?.toString()) !==
              -1

            return (
              <div className='pb-3 d-flex justify-content-between' key={index}>
                <Form.Check
                  checked={isVote}
                  id={`${index}-${isMultiAnswer ? 'checkbox' : 'radio'}`}
                  label={item.question}
                  type={isMultiAnswer ? 'checkbox' : 'radio'}
                  onChange={() => votePost(item?._id)}
                />
                {hasVote ? (
                  <div>
                    {isMultiAnswer
                      ? `${numeral(item.vote?.length).format(
                          item.vote?.length > 1000 ? '0.00a' : '0a',
                        )} ${__('forum.voteCount')}`
                      : `${total}%`}
                  </div>
                ) : null}
              </div>
            )
          })
        : null}
    </div>
  )
}
