import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ForumTopic } from '@teen-care/core'
import type { RootState } from '~core'

export interface ForumTopicState {
  currentPage: number
  forumTopics: ForumTopic[]
  loading: boolean
  totalPages: number
}

const initialState: ForumTopicState = {
  currentPage: 1,
  forumTopics: [],
  loading: false,
  totalPages: 1,
} as ForumTopicState

const forumTopicSlice = createSlice({
  initialState,
  name: 'forumTopic',
  reducers: {
    removeTopic: (state, action: PayloadAction<string>) => {
      state.forumTopics = state.forumTopics.filter(
        (forumTopic) => forumTopic._id !== action.payload,
      )
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTopic: (state, action: PayloadAction<ForumTopic>) => {
      state.forumTopics = state?.forumTopics?.length
        ? state?.forumTopics?.map((item) => {
            if (item?._id === action?.payload?._id) {
              return {
                ...item,
                ...action?.payload,
              }
            }
            return item
          })
        : [action?.payload]
    },
    setTopics: (state, action: PayloadAction<ForumTopic[]>) => {
      state.forumTopics = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const forumTopicAction = forumTopicSlice.actions

export const forumTopicState = (state: RootState) => state.forumTopic

export const forumTopicReducer = forumTopicSlice.reducer
