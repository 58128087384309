import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_USER_BADGE_ITEM, REMOVE_USER_BADGE } from '~saga'
import { getImage, useAppDispatch, useAppSelector } from '~core'

import { CreateUserBadge } from './CreateUserBadge'
import { UserBadge } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userBadgeState } from '~redux'

export function UserBadgeItem({ index, item }: { index: number; item: UserBadge }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(userBadgeState) || {}
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const {
    _id,
    creatorId,
    profiles,
    updatedAt,
    userBadgeColor,
    userBadgeIcon,
    userBadgeName,
    userBadgeNameColor,
  } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  function removeUserBadge(_id: string) {
    setShow(false)
    dispatch({
      payload: { _id },
      type: REMOVE_USER_BADGE,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_USER_BADGE_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: userBadgeColor,
              borderRadius: 20,
              flexDirection: 'row',
              padding: 5,
            }}
          >
            <img
              alt={userBadgeIcon}
              src={getImage(userBadgeIcon)}
              style={{ height: 10, marginRight: 5, width: 10 }}
            />
            <span className='fw-normal' style={{ color: userBadgeNameColor }}>
              {userBadgeName || ''}
            </span>
          </div>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{10}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <CreateUserBadge content='Sửa huy hiệu' item={item} title='Sửa' />
          <Button className='m-1' variant='outline-danger' onClick={handleShow}>
            Xóa
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa huy hiệu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Tất cả user đang gán huy hiệu này sẽ bị mất huy hiệu kể từ thời điểm xóa. Bạn có chắc
            chắn muốn xóa huy hiệu <span className='fw-bold'>{userBadgeName}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant='danger' onClick={() => removeUserBadge(_id)}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
