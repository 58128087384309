import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Lesson } from '@teen-care/core'
import type { RootState } from '~core'

export interface LessonState {
  currentPage: Record<string, number>,
  lessons: Record<string, Lesson[]>,
  loading: Record<string, boolean>,
  totalPages: Record<string, number>
}

const initialState: LessonState = {
  currentPage: {},
  lessons: {},
  loading: {},
  totalPages: {},
} as LessonState

const lessonSlice = createSlice({
  initialState,
  name: 'lesson',
  reducers: {
    removeLesson: (state, action: PayloadAction<{ courseId: string; lessonId: string }>) => {
      state.lessons = {
        ...state.lessons,
        [action.payload.courseId]: state.lessons[action.payload.courseId].filter((lesson) => lesson._id !== action.payload.lessonId),
      }
    },
    setCurrentPage: (state, action: PayloadAction<{ courseId: string; currentPage: number }>) => {
      state.currentPage = {
        ...state.currentPage,
        [action.payload.courseId]: action.payload.currentPage,
      }
    },
    setLesson: (state, action: PayloadAction<{ courseId: string; lesson: Lesson }>) => {
      state.lessons = {
        ...state.lessons,
        [action.payload.courseId]: state.lessons[action.payload.courseId].map((item) => {
          if (item?._id === action?.payload?.lesson?._id) {
            return {
              ...item,
              ...action?.payload?.lesson,
            }
          }
          return item
        }),
      }
    },
    setLessons: (state, action: PayloadAction<{ courseId: string; lessons: Lesson[] }>) => {
      state.lessons = {
        ...state.lessons,
        [action.payload.courseId]: action.payload.lessons,
      }
    },
    setLoading: (state, action: PayloadAction<{ courseId: string; loading: boolean }>) => {
      state.loading = {
        ...state.loading,
        [action.payload.courseId]: action.payload.loading,
      }
    },
    setTotalPages: (state, action: PayloadAction<{ courseId: string; totalPages: number }>) => {
      state.totalPages = {
        ...state.totalPages,
        [action.payload.courseId]: action.payload.totalPages,
      }
    },
  },
})

export const lessonAction = lessonSlice.actions

export const lessonState = (state: RootState) => state.lesson

export const lessonReducer = lessonSlice.reducer
