import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '~core'
import { UserRole } from '@teen-care/core'

export interface UserRoleState {
  currentPage: number
  loading: boolean
  totalPages: number
  userRoles: UserRole[]
}

const initialState: UserRoleState = {
  currentPage: 1,
  loading: false,
  totalPages: 1,
  userRoles: [],
} as UserRoleState

const userRoleSlice = createSlice({
  initialState,
  name: 'userRole',
  reducers: {
    removeUserRole: (state, action: PayloadAction<string>) => {
      state.userRoles = state.userRoles.filter((userRole) => userRole._id !== action.payload)
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.userRoles = state.userRoles.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setUserRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.userRoles = action.payload
    },
  },
})

export const userRoleAction = userRoleSlice.actions

export const userRoleState = (state: RootState) => state.userRole

export const userRoleReducer = userRoleSlice.reducer
