import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Course } from '@teen-care/core'
import type { RootState } from '~core'

export interface CourseState {
  courses: Course[]
  currentPage: number
  loading: boolean
  totalPages: number
}

const initialState: CourseState = {
  courses: [],
  currentPage: 1,
  loading: false,
  totalPages: 1,
} as CourseState

const courseSlice = createSlice({
  initialState,
  name: 'course',
  reducers: {
    removeCourse: (state, action: PayloadAction<string>) => {
      state.courses = state.courses.filter((course) => course._id !== action.payload)
    },
    setCourse: (state, action: PayloadAction<Course>) => {
      state.courses = state.courses.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setCourses: (state, action: PayloadAction<Course[]>) => {
      state.courses = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const courseAction = courseSlice.actions

export const courseState = (state: RootState) => state.course

export const courseReducer = courseSlice.reducer
