import { Button, Form } from 'react-bootstrap'
import { CREATE_NOTIFICATIONS, CREATE_NOTIFICATIONS_ALL } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { UserRole } from '@teen-care/core'
import { useState } from 'react'
import { userRoleState } from '~redux'

export function CreateNotification() {
  const dispatch = useAppDispatch()
  const { userRoles } = useAppSelector(userRoleState) || {}
  const [title, setTitle] = useState<string>('')
  const [body, setBody] = useState<string>('')
  const [targetUserId, setTargetUserId] = useState<string>('')
  const [screenPath, setScreenPath] = useState<string>('')
  const [postId, setPostId] = useState<string>('')
  const [lessonId, setLessonId] = useState<string>('')
  const [roleIds, setRoleIds] = useState<string[]>([])
  const isSendAll = roleIds.length === 0 && targetUserId?.length === 0

  function createNotifications() {
    dispatch({
      payload: {
        message: {
          data: { lessonId, postId, screenPath },
          notification: {
            body,
            title,
          },
        },
        targetUserId,
        targetUsers: roleIds,
      },
      type: CREATE_NOTIFICATIONS,
    })
  }
  function createNotificationsAll() {
    dispatch({
      payload: {
        message: {
          data: { lessonId, postId, screenPath },
          notification: {
            body,
            title,
          },
        },
      },
      type: CREATE_NOTIFICATIONS_ALL,
    })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Tạo thông báo</Form.Label>
      <Form.Group className='mb-3'>
        <Form.Label className='fw-bold mb-1'>Title</Form.Label>
        <Form.Control
          required
          placeholder='Nhập tiêu đề thông báo'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label className='fw-bold mb-1'>Content</Form.Label>
        <Form.Control
          required
          placeholder='Nhập tên nội dung thông báo'
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label className='fw-bold mb-1'>UserId</Form.Label>
        <Form.Control
          required
          placeholder='Nhập user id'
          value={targetUserId}
          onChange={(e) => setTargetUserId(e.target.value)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label className='fw-bold mb-1'>Path</Form.Label>
        <Form.Select
          required
          onChange={(e: any) => {
            setScreenPath(e.target.value === 'default' ? '' : e.target.value)
          }}
        >
          <option value='default'>Chọn đối tượng</option>
          <option value='ForumStack/PostDetail'>Post Detail</option>
          <option value='LearnStack/LearnReport'>Learn Report</option>
          <option value='LearnStack/LearnLesson'>Learn Course</option>
          <option value='LearnStack/LearnQuestion'>Learn Lesson</option>
        </Form.Select>
      </Form.Group>
      {screenPath === 'ForumStack/PostDetail' && (
        <Form.Group className='mb-3'>
          <Form.Label className='fw-bold mb-1'>PostId</Form.Label>
          <Form.Control
            placeholder='Nhập id bài Post'
            value={postId}
            onChange={(e) => setPostId(e.target.value)}
          />
        </Form.Group>
      )}
      {(screenPath === 'LearnStack/LearnReport' || screenPath === 'LearnStack/LearnLesson') && (
        <Form.Group className='mb-3'>
          <Form.Label className='fw-bold mb-1'>LessonId</Form.Label>
          <Form.Control
            placeholder='Nhập id bài học'
            value={lessonId}
            onChange={(e) => setLessonId(e.target.value)}
          />
        </Form.Group>
      )}
      <Form.Group className='mb-3'>
        <Form.Label className='fw-bold mb-1'>
          Target User (Nếu không tick vào bất kì ô nào mặc định gửi cho tất cả user)
        </Form.Label>
        {userRoles.map((item: UserRole) => {
          const isChecked =
            roleIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
          return (
            <Form.Check
              checked={isChecked}
              className='mb-1'
              key={item?._id}
              label={item?.userRoleName}
              onChange={(e: any) => {
                setRoleIds((current) =>
                  e?.target?.checked
                    ? [item?._id, ...current]
                    : current?.filter((e) => e !== item?._id),
                )
              }}
            />
          )
        })}
      </Form.Group>
      <Button variant='dark' onClick={isSendAll ? createNotificationsAll : createNotifications}>
        {'Tạo thông báo'}
      </Button>
    </div>
  )
}
