import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '~core'
import { Topic } from '@teen-care/core'

export interface TopicState {
  currentPage: number
  loading: boolean
  topics: Array<Topic>
  totalPages: number
}

const initialState: TopicState = {
  currentPage: 1,
  loading: false,
  topics: [],
  totalPages: 1,
} as TopicState

const topicSlice = createSlice({
  initialState,
  name: 'topic',
  reducers: {
    removeTopic: (state, action: PayloadAction<string>) => {
      state.topics = state.topics.filter((topic) => topic._id !== action.payload)
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTopic: (state, action: PayloadAction<Topic>) => {
      state.topics = state?.topics?.length
        ? state?.topics?.map((item) => {
            if (item?._id === action?.payload?._id) {
              return {
                ...item,
                ...action?.payload,
              }
            }
            return item
          })
        : [action?.payload]
    },
    setTopics: (state, action: PayloadAction<Array<Topic>>) => {
      state.topics = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const topicAction = topicSlice.actions

export const topicState = (state: RootState) => state.topic

export const topicReducer = topicSlice.reducer
