import { __ } from '~localization'
import dayjs from 'dayjs'

export function truncateString(str: string, maxLength: number) {
  if (maxLength === 0) return str
  if (str?.length <= maxLength) return str
  const truncated = str?.slice(0, maxLength)
  const lastSpaceIndex = truncated?.lastIndexOf(' ')
  if (lastSpaceIndex !== -1) {
    return truncated?.slice(0, lastSpaceIndex)
  }
  return truncated
}

export function formatTimeDifference(postDate: Date | number): string {
  const now = dayjs()
  const postDayjs = dayjs(postDate)
  const diffInMinutes = now.diff(postDayjs, 'minute')
  const diffInHours = now.diff(postDayjs, 'hour')
  const diffInDays = now.diff(postDayjs, 'day')
  const oneMonthLater = postDayjs.add(1, 'month')

  if (diffInMinutes < 1) {
    return __('dateTime.justDone')
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${__('dateTime.minute')}`
  } else if (diffInHours <= 24) {
    return `${diffInHours} ${__('dateTime.hour')}`
  } else if (now.isBefore(oneMonthLater)) {
    return `${diffInDays} ${__('dateTime.day')}`
  } else {
    return postDayjs.format('DD/MM/YYYY')
  }
}
