import { BsIncognito } from 'react-icons/bs'

interface AvatarProps {
  anonymous?: boolean
  avatarUrl?: string
  className?: string
  small?: boolean
}

export function Avatar({
  anonymous = false,
  avatarUrl = '',
  className = '',
  small = false,
}: AvatarProps) {
  return (
    <div
      className={className}
      style={{
        backgroundColor: avatarUrl ? 'transparent' : '#D9D9D9',
        borderRadius: '50%',
        height: small ? 18 : 36,
        overflow: 'hidden',
        width: small ? 18 : 36,
      }}
    >
      {anonymous ? (
        <BsIncognito
          color='#A0A0A0'
          style={{ height: '100%', objectFit: 'cover', width: '100%' }}
        />
      ) : null}
      {!anonymous && avatarUrl ? (
        <img
          alt='avatar'
          className='img-fluid'
          src={avatarUrl || ''}
          style={{ height: '100%', objectFit: 'cover', width: '100%' }}
        />
      ) : null}
    </div>
  )
}
