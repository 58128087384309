import { Card, Dropdown, Form, Table } from 'react-bootstrap'
import { GET_FORUM_GROUPS, GET_FORUM_TOPICS, GET_POSTS, LOAD_MORE_POSTS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { Paging } from '~components'
import { PostItem } from './components'
import { debounce } from 'lodash'
import { postState } from '~redux'

export function Posts() {
  const dispatch = useAppDispatch()
  const { currentPage, posts, totalPages } = useAppSelector(postState) || {}
  const [search, setSearch] = useState<string>('')
  const [postStatus, setPostStatus] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)

  const postStatusData = [
    { title: 'Tất cả trạng thái', value: '' },
    { title: 'Chờ duyệt', value: 'PENDING' },
    { title: 'Đã duyệt', value: 'APPROVE' },
    { title: 'Từ chối', value: 'REJECT' },
    { title: 'Đã xóa', value: 'DELETE' },
  ]

  useEffect(() => {
    dispatch({ type: GET_FORUM_GROUPS })
    dispatch({ type: GET_FORUM_TOPICS })
  }, [])

  useEffect(() => {
    getPosts()
  }, [postStatus])

  useEffect(() => {
    const debouncedGetPosts = debounce(getPosts, 1000)
    debouncedGetPosts()
    return () => {
      debouncedGetPosts.cancel()
    }
  }, [search])

  function getPosts() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { postStatus, search },
      type: GET_POSTS,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { page, postStatus, search }, type: LOAD_MORE_POSTS })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Cộng đồng</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Label className='fw-bold'>Tiêu đề</Form.Label>
        <Form.Control
          className='w-50'
          placeholder='Tìm kiếm'
          onChange={(e) => setSearch(e.target.value)}
        />
        <Form.Label className='fw-bold'>Trạng thái</Form.Label>
        <Dropdown>
          <Dropdown.Toggle variant='outline-dark'>
            {postStatusData.find((e) => e.value === postStatus)?.title}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {postStatusData.map((item, index) => (
              <Dropdown.Item
                active={item.value === postStatus}
                className={item.value === postStatus ? 'bg-dark' : 'text-dark'}
                key={index}
                onClick={() => setPostStatus(item.value)}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {[
                  '#',
                  'Nhóm',
                  'Người đăng',
                  'Nội dung bài viết',
                  'Topic',
                  'Trạng thái',
                  'Thời gian',
                  'Thao tác',
                ].map((item, index) => (
                  <th className='border-bottom' key={`${index}`}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {posts.map((item, index) => (
                <PostItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
