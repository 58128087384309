import { UserBadge, UserProfile } from '@teen-care/core'
import { apiPost, apiPostFormData } from '~core'
import { put, select, takeEvery } from 'redux-saga/effects'
import { userAction, userBadgeAction, userBadgeState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'

export const GET_USER_BADGES = 'GET_USER_BADGES'
export const LOAD_MORE_USER_BADGES = 'LOAD_MORE_USER_BADGES'
export const GET_USER_BADGE = 'GET_USER_BADGE'
export const CREATE_USER_BADGE = 'CREATE_USER_BADGE'
export const UPDATE_USER_BADGE = 'UPDATE_USER_BADGE'
export const REMOVE_USER_BADGE = 'REMOVE_USER_BADGE'
export const SET_USER_BADGE = 'SET_USER_BADGE'
export const UNSET_USER_BADGE = 'UNSET_USER_BADGE'

export const LIMIT_USER_BADGE_ITEM = 10

const forumAdminRouteName = {
  route: '/forum-admin',
  userBadgeCreate: '/create-user-badge',
  userBadgeGet: '/get-user-badge',
  userBadgeGets: '/get-user-badges',
  userBadgeRemove: '/remove-user-badge',
  userBadgeSet: '/set-user-badge',
  userBadgeUnset: '/unset-user-badge',
  userBadgeUpdate: '/update-user-badge',
}

interface CreateUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    media?: any
    userBadgeColor: string
    userBadgeName: string
    userBadgeNameColor: string
  }
}
function* createUserBadge({ onFailure, onSuccess, payload }: CreateUserBadgeParams) {
  try {
    const { media, userBadgeColor, userBadgeName, userBadgeNameColor } = payload || {}
    const params = new FormData()
    params.append('userBadgeColor', userBadgeColor)
    params.append('userBadgeName', userBadgeName)
    params.append('userBadgeNameColor', userBadgeNameColor)
    media && params.append('media', media)

    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeCreate}`,
    })
    const { userBadges } = yield select(userBadgeState)
    yield put(
      userBadgeAction.setPostBadges([
        response?.data?.result?.userBadge as UserBadge,
        ...userBadges,
      ] as UserBadge[]),
    )
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadge)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    media?: any
    userBadgeColor: string
    userBadgeName: string
    userBadgeNameColor: string
  }
}
function* updateUserBadge({ onFailure, onSuccess, payload }: UpdateUserBadgeParams) {
  try {
    const { _id, media, userBadgeColor, userBadgeName, userBadgeNameColor } = payload || {}
    const params = new FormData()
    params.append('_id', _id)
    params.append('userBadgeColor', userBadgeColor)
    params.append('userBadgeName', userBadgeName)
    params.append('userBadgeNameColor', userBadgeNameColor)
    media && params.append('media', media)
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeUpdate}`,
    })
    yield put(userBadgeAction.setPostBadge(response?.data?.result?.userBadge as UserBadge))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadge)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeUserBadge({ onFailure, onSuccess, payload }: RemoveUserBadgeParams) {
  try {
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeRemove}`,
    })
    yield put(userBadgeAction.removePostBadge(response?.data?.result?.userBadge?._id))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadge)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserBadgesParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getUserBadges({ onFailure, onSuccess, payload }: GetUserBadgesParams) {
  try {
    yield put(userBadgeAction.setLoading(true))
    yield put(userBadgeAction.setCurrentPage(1))
    yield put(userBadgeAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_BADGE_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeGets}`,
    })
    yield put(userBadgeAction.setPostBadges(response?.data?.result?.userBadges as UserBadge[]))
    yield put(userBadgeAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(userBadgeAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadges)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreUserBadges({ onFailure, onSuccess, payload }: GetUserBadgesParams) {
  try {
    const { currentPage, totalPages } = yield select(userBadgeState)
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_BADGE_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeGets}`,
    })
    yield put(userBadgeAction.setPostBadges(response?.data?.result?.userBadges as UserBadge[]))
    yield put(userBadgeAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(userBadgeAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadges)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getUserBadge({ onFailure, onSuccess, payload }: GetUserBadgeParams) {
  try {
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeGet}`,
    })
    yield put(userBadgeAction.setPostBadge(response?.data?.result?.userBadge as UserBadge))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userBadge)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface SetUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { userBadgeId: string; userId: string }
}
function* setUserBadge({ onFailure, onSuccess, payload }: SetUserBadgeParams) {
  try {
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeSet}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UnsetUserBadgeParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { userId: string }
}
function* unsetUserBadge({ onFailure, onSuccess, payload }: UnsetUserBadgeParams) {
  try {
    yield put(userBadgeAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userBadgeUnset}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userBadgeAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userBadgeAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* userBadgeSaga() {
  yield takeEvery(GET_USER_BADGES, getUserBadges)
  yield takeEvery(LOAD_MORE_USER_BADGES, loadMoreUserBadges)
  yield takeEvery(GET_USER_BADGE, getUserBadge)
  yield takeEvery(CREATE_USER_BADGE, createUserBadge)
  yield takeEvery(UPDATE_USER_BADGE, updateUserBadge)
  yield takeEvery(REMOVE_USER_BADGE, removeUserBadge)
  yield takeEvery(SET_USER_BADGE, setUserBadge)
  yield takeEvery(UNSET_USER_BADGE, unsetUserBadge)
}
