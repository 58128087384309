import { put, select, takeEvery } from 'redux-saga/effects'
import { userRankingAction, userRankingState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { UserRanking } from '@teen-care/core'
import { apiPost } from '~core'

export const GET_USER_RANKINGS = 'GET_USER_RANKINGS'
export const LOAD_MORE_USER_RANKINGS = 'LOAD_MORE_USER_RANKINGS'
export const GET_USER_RANKING = 'GET_USER_RANKING'
export const CREATE_USER_RANKING = 'CREATE_USER_RANKING'
export const UPDATE_USER_RANKING = 'UPDATE_USER_RANKING'
export const REMOVE_USER_RANKING = 'REMOVE_USER_RANKING'

export const LIMIT_USER_RANKING_ITEM = 10

const forumAdminRouteName = {
  route: '/user-profile-admin',
  userRankingCreate: '/create-user-ranking',
  userRankingGet: '/get-user-ranking',
  userRankingGets: '/get-user-rankings',
  userRankingRemove: '/remove-user-ranking',
  userRankingUpdate: '/update-user-ranking',
}

interface CreateUserRankingParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    rankName: string
    score: number
  }
}
function* createUserRanking({ onFailure, onSuccess, payload }: CreateUserRankingParams) {
  try {
    yield put(userRankingAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingCreate}`,
    })
    const { userRankings } = yield select(userRankingState)
    yield put(
      userRankingAction.setUserRankings([
        response?.data?.result?.userRanking as UserRanking,
        ...userRankings,
      ] as Array<UserRanking>),
    )
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateUserRankingParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    rankName: string
    score: number
  }
}
function* updateUserRanking({ onFailure, onSuccess, payload }: UpdateUserRankingParams) {
  try {
    yield put(userRankingAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingUpdate}`,
    })
    yield put(userRankingAction.setUserRanking(response?.data?.result?.userRanking as UserRanking))
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveUserRankingParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeUserRanking({ onFailure, onSuccess, payload }: RemoveUserRankingParams) {
  try {
    yield put(userRankingAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingRemove}`,
    })
    yield put(userRankingAction.removeUserRanking(response?.data?.result?.userRanking?._id))
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserRankingsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getUserRankings({ onFailure, onSuccess, payload }: GetUserRankingsParams) {
  try {
    yield put(userRankingAction.setLoading(true))
    yield put(userRankingAction.setCurrentPage(1))
    yield put(userRankingAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_RANKING_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingGets}`,
    })
    yield put(
      userRankingAction.setUserRankings(response?.data?.result?.userRankings as Array<UserRanking>),
    )
    yield put(userRankingAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(userRankingAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRankings)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreUserRankings({ onFailure, onSuccess, payload }: GetUserRankingsParams) {
  try {
    const { currentPage, totalPages } = yield select(userRankingState)
    yield put(userRankingAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_RANKING_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingGets}`,
    })
    yield put(
      userRankingAction.setUserRankings(response?.data?.result?.userRankings as Array<UserRanking>),
    )
    yield put(userRankingAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(userRankingAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRankings)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserRankingParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getUserRanking({ onFailure, onSuccess, payload }: GetUserRankingParams) {
  try {
    yield put(userRankingAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRankingGet}`,
    })
    yield put(userRankingAction.setUserRanking(response?.data?.result?.userRanking as UserRanking))
    yield put(userRankingAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userRankingAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* userRankingSaga() {
  yield takeEvery(GET_USER_RANKING, getUserRanking)
  yield takeEvery(GET_USER_RANKINGS, getUserRankings)
  yield takeEvery(LOAD_MORE_USER_RANKINGS, loadMoreUserRankings)
  yield takeEvery(CREATE_USER_RANKING, createUserRanking)
  yield takeEvery(UPDATE_USER_RANKING, updateUserRanking)
  yield takeEvery(REMOVE_USER_RANKING, removeUserRanking)
}
