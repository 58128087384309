import { UserProfile, UserRole } from '@teen-care/core'
import { put, select, takeEvery } from 'redux-saga/effects'
import { userAction, userRoleAction, userRoleState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'

export const GET_USER_ROLES = 'GET_USER_ROLES'
export const LOAD_MORE_USER_ROLES = 'LOAD_MORE_USER_ROLES'
export const GET_USER_ROLE = 'GET_USER_ROLE'
export const CREATE_USER_ROLE = 'CREATE_USER_ROLE'
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const REMOVE_USER_ROLE = 'REMOVE_USER_ROLE'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const UNSET_USER_ROLE = 'UNSET_USER_ROLE'

export const LIMIT_USER_ROLE_ITEM = 0

const forumAdminRouteName = {
  route: '/user-profile-admin',
  userRoleCreate: '/create-user-role',
  userRoleGet: '/get-user-role',
  userRoleGets: '/get-user-roles',
  userRoleRemove: '/remove-user-role',
  userRoleUpdate: '/update-user-role',
  userSetRole: '/set-user-role',
}

interface CreateUserRoleParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    forumGroupIds: string[]
    forumTopicIds: string[]
    learnCourseIds: { _id: string; read: boolean; write: boolean }[]
    userRoleName: string
  }
}
function* createUserRole({ onFailure, onSuccess, payload }: CreateUserRoleParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleCreate}`,
    })
    const { userRoles } = yield select(userRoleState)
    yield put(
      userRoleAction.setUserRoles([
        response?.data?.result?.userRole as UserRole,
        ...userRoles,
      ] as UserRole[]),
    )
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRole)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateUserRoleParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    forumGroupIds: string[]
    forumTopicIds: string[]
    learnCourseIds: { _id: string; read: boolean; write: boolean }[]
    userRoleName: string
  }
}
function* updateUserRole({ onFailure, onSuccess, payload }: UpdateUserRoleParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleUpdate}`,
    })
    yield put(userRoleAction.setUserRole(response?.data?.result?.userRole as UserRole))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRole)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveUserRoleParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeUserRole({ onFailure, onSuccess, payload }: RemoveUserRoleParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleRemove}`,
    })
    yield put(userRoleAction.removeUserRole(response?.data?.result?.userRole?._id))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRole)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserRolesParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getUserRoles({ onFailure, onSuccess, payload }: GetUserRolesParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    yield put(userRoleAction.setCurrentPage(1))
    yield put(userRoleAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_ROLE_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleGets}`,
    })
    yield put(userRoleAction.setUserRoles(response?.data?.result?.userRoles as UserRole[]))
    yield put(userRoleAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(userRoleAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRoles)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreUserRoles({ onFailure, onSuccess, payload }: GetUserRolesParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_ROLE_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleGets}`,
    })
    yield put(userRoleAction.setUserRoles(response?.data?.result?.userRoles as UserRole[]))
    yield put(userRoleAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(userRoleAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRoles)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserRoleParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getUserRole({ onFailure, onSuccess, payload }: GetUserRoleParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userRoleGet}`,
    })
    yield put(userRoleAction.setUserRole(response?.data?.result?.userRole as UserRole))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.userRole)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

interface SetUserRoleParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { userId: string; userRoleId: string }
}
function* setUserRole({ onFailure, onSuccess, payload }: SetUserRoleParams) {
  try {
    yield put(userRoleAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.userSetRole}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userRoleAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userRoleAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* userRoleSaga() {
  yield takeEvery(GET_USER_ROLES, getUserRoles)
  yield takeEvery(LOAD_MORE_USER_ROLES, loadMoreUserRoles)
  yield takeEvery(GET_USER_ROLE, getUserRole)
  yield takeEvery(CREATE_USER_ROLE, createUserRole)
  yield takeEvery(UPDATE_USER_ROLE, updateUserRole)
  yield takeEvery(REMOVE_USER_ROLE, removeUserRole)
  yield takeEvery(SET_USER_ROLE, setUserRole)
}
