import { forumGroupAction, forumGroupState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { ForumGroup } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'

export const GET_FORUM_GROUPS = 'GET_FORUM_GROUPS'
export const LOAD_MORE_FORUM_GROUPS = 'LOAD_MORE_FORUM_GROUPS'
export const GET_FORUM_GROUP = 'GET_FORUM_GROUP'
export const CREATE_FORUM_GROUP = 'CREATE_FORUM_GROUP'
export const UPDATE_FORUM_GROUP = 'UPDATE_FORUM_GROUP'
export const REMOVE_FORUM_GROUP = 'REMOVE_FORUM_GROUP'

export const LIMIT_GROUP_ITEM = 0

const forumAdminRouteName = {
  groupCreate: '/create-group',
  groupGet: '/get-group',
  groupGets: '/get-groups',
  groupRemove: '/remove-group',
  groupUpdate: '/update-group',
  route: '/forum-admin',
}

interface CreateGroupParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    adminIds?: string[]
    groupName: string
    introduction: string
    roleIds?: string[]
    userIds?: string[]
  }
}
function* createGroup({ onFailure, onSuccess, payload }: CreateGroupParams) {
  try {
    yield put(forumGroupAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupCreate}`,
    })
    const { forumGroups } = yield select(forumGroupState)
    yield put(
      forumGroupAction.setGroups([
        response?.data?.result?.forumGroup as ForumGroup,
        ...forumGroups,
      ] as ForumGroup[]),
    )
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumGroup)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateGroupParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    adminIds?: string[]
    groupName: string
    introduction: string
    roleIds?: string[]
    userIds?: string[]
  }
}
function* updateGroup({ onFailure, onSuccess, payload }: UpdateGroupParams) {
  try {
    yield put(forumGroupAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupUpdate}`,
    })
    yield put(forumGroupAction.setGroup(response?.data?.result?.forumGroup as ForumGroup))
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumGroup)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveGroupParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeGroup({ onFailure, onSuccess, payload }: RemoveGroupParams) {
  try {
    yield put(forumGroupAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupRemove}`,
    })
    yield put(forumGroupAction.removeGroup(response?.data?.result?.forumGroup?._id))
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumGroup)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetGroupsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getGroups({ onFailure, onSuccess, payload }: GetGroupsParams) {
  try {
    yield put(forumGroupAction.setLoading(true))
    yield put(forumGroupAction.setCurrentPage(1))
    yield put(forumGroupAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_GROUP_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupGets}`,
    })
    yield put(forumGroupAction.setGroups(response?.data?.result?.forumGroups as ForumGroup[]))
    yield put(forumGroupAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(forumGroupAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumGroups)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreGroups({ onFailure, onSuccess, payload }: GetGroupsParams) {
  try {
    const { currentPage, totalPages } = yield select(forumGroupState)
    yield put(forumGroupAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_GROUP_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupGets}`,
    })
    yield put(forumGroupAction.setGroups(response?.data?.result?.forumGroups as ForumGroup[]))
    yield put(forumGroupAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(forumGroupAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.forumGroups)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetGroupParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getGroup({ onFailure, onSuccess, payload }: GetGroupParams) {
  try {
    yield put(forumGroupAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.groupGet}`,
    })
    yield put(forumGroupAction.setGroup(response?.data?.result?.forumGroup as ForumGroup))
    yield put(forumGroupAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topic)
  } catch (e: any) {
    yield put(forumGroupAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* forumGroupSaga() {
  yield takeEvery(GET_FORUM_GROUPS, getGroups)
  yield takeEvery(LOAD_MORE_FORUM_GROUPS, loadMoreGroups)
  yield takeEvery(GET_FORUM_GROUP, getGroup)
  yield takeEvery(CREATE_FORUM_GROUP, createGroup)
  yield takeEvery(UPDATE_FORUM_GROUP, updateGroup)
  yield takeEvery(REMOVE_FORUM_GROUP, removeGroup)
}
