import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_USER_SCORE_ITEM, REMOVE_USER_SCORE } from '~saga'
import { UserProfile, UserScore } from '@teen-care/core'
import { useAppDispatch, useAppSelector } from '~core'

import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userState } from '~redux'

export function UserScoreItem({ index, item }: { index: number; item: UserScore }) {
  const { currentPage, users } = useAppSelector(userState) || {}
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false)

  const { _id, creatorId, profiles, score, scoreDescription, updatedAt, userId } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })
  const user = (users?.find((e) => e._id === userId) || {}) as UserProfile

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  function removeUserScore() {
    setShow(false)
    dispatch({
      payload: {
        _id,
        userId,
      },
      type: REMOVE_USER_SCORE,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_USER_SCORE_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{user?.username}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{user?.name}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{score}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{scoreDescription}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <Button className='m-1' variant='outline-danger' onClick={handleShow}>
            Xóa
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa điểm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Bạn có chắc chắn muốn xoá lịch sử điểm của{' '}
            <span className='fw-bold'>{`[${user?.username}] ${user?.name}`}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant={'danger'} onClick={removeUserScore}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
