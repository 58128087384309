import { useAppDispatch, useAppSelector } from '~core'

import { Avatar } from '~components'
import { CREATE_COMMENT } from '~saga'
import { Form } from 'react-bootstrap'
import { IoMdSend } from 'react-icons/io'
import { useState } from 'react'
import { userProfileState } from '~redux'

export function CommentInputBar({ postId }: { postId: string }) {
  const [content, setContent] = useState<string>('')
  const [isPrivate, setPrivate] = useState<boolean>(false)
  const { userProfile } = useAppSelector(userProfileState) || {}
  const dispatch = useAppDispatch()

  function createComment() {
    dispatch({
      onSuccess: () => setContent(''),
      payload: { content, isPrivate, postId },
      type: CREATE_COMMENT,
    })
  }

  return (
    <div className='border-top pt-2 pb-2'>
      <Form.Check
        checked={isPrivate}
        label='Hiển thị dạng ẩn danh'
        type='switch'
        onChange={() => setPrivate((current) => !current)}
      />
      <div className='d-flex align-items-center'>
        <Avatar avatarUrl={userProfile?.avatar} />
        <Form.Control
          className='m-2'
          placeholder='Nhập bình luận'
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <IoMdSend size={30} onClick={createComment} />
      </div>
    </div>
  )
}
