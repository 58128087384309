import { Avatar, Slider } from '~components'
import {
  CommentInputBar,
  CommentItem,
  PollPost,
  PostHistoryComponent,
  ReactionPost,
} from './components'
import { GET_COMMENTS, GET_POST, GET_POST_HISTORY, MANAGE_POST } from '~saga'
import { commentState, postHistoryState, postState } from '~redux'
import { formatTimeDifference, getUserProfile, toastServices } from '~utils'
import { useAppDispatch, useAppSelector } from '~core'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Post } from '@teen-care/core'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

export function PostDetail() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const postId = params?._id || ''
  const { posts } = useAppSelector(postState) || {}
  const commentsRedux = useAppSelector(commentState) || {}
  const postHistoryRedux = useAppSelector(postHistoryState) || {}
  const post: Post = (posts?.find((e) => e._id === postId) || {}) as Post
  const { content, createdAt, creatorId, isPrivate, media, postStatus, profiles } = post || {}
  const creator = getUserProfile({ profiles, userId: creatorId })
  const comments = commentsRedux?.comments?.[post?._id] || []
  const postHistory = postHistoryRedux?.postHistory?.[post?._id] || []

  useEffect(() => {
    fetchData()
  }, [postId])

  function fetchData() {
    if (postId && postId.length) {
      getPost()
      getComments()
      getPostHistory()
    }
  }

  function getPost() {
    dispatch({
      onFailure: () => navigate(-1),
      payload: { _id: postId },
      type: GET_POST,
    })
  }

  function getComments() {
    dispatch({
      payload: { postId },
      type: GET_COMMENTS,
    })
  }

  function getPostHistory() {
    dispatch({
      payload: { postId },
      type: GET_POST_HISTORY,
    })
  }

  function managePost(postStatus: string) {
    if (postStatus === 'REJECT') {
      toastServices.prompt({
        content: 'Nhập lý do từ chối',
        onConfirm: (reasonReject) => {
          dispatch({
            payload: { _id: postId, postStatus, reasonReject },
            type: MANAGE_POST,
          })
        },
        showPrompt: true,
      })
    } else
      dispatch({
        payload: { _id: postId, postStatus },
        type: MANAGE_POST,
      })
  }

  return (
    <div className='container-fluid'>
      <div className='border-bottom p-3 d-flex align-items-center justify-content-between'>
        <div className=' fw-bold'>Chi tiết bài viết</div>
        <FontAwesomeIcon icon={faClose} onClick={() => navigate(-1)} />
      </div>
      <div className='d-flex'>
        <div className='p-3 border-right' style={{ width: '60%' }}>
          <div className='row d-flex align-items-center'>
            <Avatar avatarUrl={creator.avatar} />
            <div className='col'>
              <div className='fw-bold'>
                {creator?.name} {isPrivate ? '(Ẩn danh)' : ''}
              </div>
              <div>{formatTimeDifference(createdAt)}</div>
            </div>
          </div>
          <div className='mt-3 mb-3'>{content}</div>
          <PollPost {...post} />
          <Slider image={media} />

          <ReactionPost item={post} />
          {comments.map((item, index) => {
            return <CommentItem {...item} key={index} profiles={profiles} />
          })}
          <CommentInputBar postId={postId} />
        </div>
        <div className='p-3 border border-top-0 border-bottom-0' style={{ width: '40%' }}>
          <div className='d-flex align-items-center pb-3'>
            <div className='w-50 fw-bold'>Trạng thái: </div>
            {postStatus === 'APPROVE' && (
              <div className='m-1 w-50 rounded text-center bg-success text-white p-1'>Đã duyệt</div>
            )}
            {postStatus === 'PENDING' && (
              <div className='m-1 w-50 rounded text-center bg-warning text-white p-1'>
                Chờ duyệt
              </div>
            )}
            {postStatus === 'REJECT' && (
              <div className='m-1 w-50 rounded text-center bg-danger text-white p-1'>Từ chối</div>
            )}
            {postStatus === 'DELETE' && (
              <div className='m-1 w-50 rounded text-center bg-secondary text-white p-1'>Đã xóa</div>
            )}
          </div>

          {postStatus === 'APPROVE' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='danger' onClick={() => managePost('REJECT')}>
                Từ chối bài
              </Button>
              <Button className='m-1 w-100' variant='danger' onClick={() => managePost('DELETE')}>
                Xóa bài
              </Button>
            </div>
          ) : null}
          {postStatus === 'PENDING' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='info' onClick={() => managePost('APPROVE')}>
                Duyệt bài
              </Button>
              <Button className='m1 w-100' variant='danger' onClick={() => managePost('REJECT')}>
                Từ chối bài
              </Button>
            </div>
          ) : null}
          {postStatus === 'REJECT' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='info' onClick={() => managePost('APPROVE')}>
                Duyệt bài
              </Button>
              <Button className='m1 w-100' variant='danger' onClick={() => managePost('DELETE')}>
                Ẩn bài
              </Button>
            </div>
          ) : null}

          <PostHistoryComponent postHistory={postHistory} />
        </div>
      </div>
    </div>
  )
}
