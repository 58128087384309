import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ForumGroup } from '@teen-care/core'
import type { RootState } from '~core'

export interface ForumGroupState {
  currentPage: number
  forumGroups: ForumGroup[]
  loading: boolean
  totalPages: number
}

const initialState: ForumGroupState = {
  currentPage: 1,
  forumGroups: [],
  loading: false,
  totalPages: 1,
} as ForumGroupState

const forumGroupSlice = createSlice({
  initialState,
  name: 'forumGroup',
  reducers: {
    removeGroup: (state, action: PayloadAction<string>) => {
      state.forumGroups = state.forumGroups.filter(
        (forumGroup) => forumGroup._id !== action.payload,
      )
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setGroup: (state, action: PayloadAction<ForumGroup>) => {
      state.forumGroups = state?.forumGroups?.length
        ? state?.forumGroups?.map((item) => {
            if (item?._id === action?.payload?._id) {
              return {
                ...item,
                ...action?.payload,
              }
            }
            return item
          })
        : [action?.payload]
    },
    setGroups: (state, action: PayloadAction<ForumGroup[]>) => {
      state.forumGroups = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const forumGroupAction = forumGroupSlice.actions

export const forumGroupState = (state: RootState) => state.forumGroup

export const forumGroupReducer = forumGroupSlice.reducer
