import { apiPost, apiPostFormData } from '~core'
import { lessonAction, lessonState, userRoleState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { Lesson } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'

export const GET_LESSONS = 'GET_LESSONS'
export const LOAD_MORE_LESSONS = 'LOAD_MORE_LESSONS'
export const CREATE_LESSON = 'CREATE_LESSON'
export const UPDATE_LESSON = 'UPDATE_LESSON'
export const DELETE_LESSON = 'DELETE_LESSON'

export const LIMIT_LESSON_ITEM = 20

const LearnRouteName = {
  lessonCreate: '/create-lesson',
  lessonDelete: '/delete-lesson',
  lessonGets: '/get-lessons',
  lessonUpdate: '/update-lesson',
  route: '/learn-admin',
}

interface GetLessonsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { courseId: string, limit?: number; page?: number; search?: string }
}
function* getLessons({ onFailure, onSuccess, payload }: GetLessonsParams) {
  try {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: true }))
    yield put(lessonAction.setCurrentPage({ courseId: payload.courseId, currentPage: 1 }))
    yield put(lessonAction.setTotalPages({ courseId: payload.courseId, totalPages: 1 }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_LESSON_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.lessonGets}`,
    })
    yield put(lessonAction.setLessons({ courseId: payload.courseId, lessons: response?.data?.result?.lessons as Lesson[] }))
    yield put(lessonAction.setCurrentPage({ courseId: payload.courseId, currentPage: response?.data?.result?.currentPage || 1 }))
    yield put(lessonAction.setTotalPages({ courseId: payload.courseId, totalPages: response?.data?.result?.totalPages || 1 }))
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onSuccess?.(response?.data?.result?.lessons)
  } catch (e: any) {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onFailure?.(e)
  }
}

function* loadMoreLessons({ onFailure, onSuccess, payload }: GetLessonsParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: true }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_LESSON_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.lessonGets}`,
    })
    yield put(lessonAction.setLessons({ courseId: payload.courseId, lessons: response?.data?.result?.lessons as Lesson[] }))
    yield put(lessonAction.setCurrentPage({ courseId: payload.courseId, currentPage: response?.data?.result?.currentPage || currentPage }))
    yield put(lessonAction.setTotalPages({ courseId: payload.courseId, totalPages: response?.data?.result?.totalPages || totalPages }))
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onSuccess?.(response?.data?.result?.lessons)
  } catch (e: any) {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onFailure?.(e)
  }
}

interface CreateLessonParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    courseId: string
    lessonDescription: string
    lessonName: string
    lessonSubDescription: string
    media?: any[],
    score: number
  }
}
function* createLesson({ onFailure, onSuccess, payload }: CreateLessonParams) {
  try {
    const { lessons } = yield select(lessonState)
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: true }))
    const { courseId, lessonDescription, lessonName, lessonSubDescription, media, score } = payload
    const params = new FormData()
    params.append('courseId', courseId)
    params.append('lessonDescription', lessonDescription)
    params.append('lessonName', lessonName)
    params.append('lessonSubDescription', lessonSubDescription)
    params.append('score', score?.toString())
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.lessonCreate}`,
    })
    yield put(lessonAction.setLessons({ courseId: payload.courseId, lessons: [response?.data?.result?.lesson as Lesson, ...lessons] }))
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onSuccess?.(response?.data?.result?.lesson)
  } catch (e: any) {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onFailure?.(e)
  }
}

interface UpdateLessonParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    courseId: string
    lessonDescription: string
    lessonName: string
    lessonSubDescription: string
    media?: any[],
    score: number
  }
}
function* updateLesson({ onFailure, onSuccess, payload }: UpdateLessonParams) {
  try {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: true }))
    const { _id, courseId, lessonDescription, lessonName, lessonSubDescription, media, score } = payload
    const params = new FormData()
    params.append('_id', _id)
    params.append('courseId', courseId)
    params.append('lessonDescription', lessonDescription)
    params.append('lessonName', lessonName)
    params.append('lessonSubDescription', lessonSubDescription)
    params.append('score', score?.toString())
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.lessonUpdate}`,
    })
    yield put(lessonAction.setLesson({ courseId: payload.courseId, lesson: response?.data?.result?.lesson as Lesson }))
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onSuccess?.(response?.data?.result?.lesson)
  } catch (e: any) {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onFailure?.(e)
  }
}

interface DeleteLessonParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { courseId: string, lessonId: string }
}
function* deleteLesson({ onFailure, onSuccess, payload }: DeleteLessonParams) {
  try {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: true }))
    const response: AxiosResponse = yield apiPost({
      params: { _id: payload.lessonId },
      url: `${LearnRouteName.route}${LearnRouteName.lessonDelete}`,
    })
    yield put(lessonAction.removeLesson({ courseId: payload.courseId, lessonId: payload.lessonId }))
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    yield put(lessonAction.setLoading({ courseId: payload.courseId, loading: false }))
    onFailure?.(e)
  }
}

export function* lessonSaga() {
  yield takeEvery(GET_LESSONS, getLessons)
  yield takeEvery(LOAD_MORE_LESSONS, loadMoreLessons)
  yield takeEvery(CREATE_LESSON, createLesson)
  yield takeEvery(UPDATE_LESSON, updateLesson)
  yield takeEvery(DELETE_LESSON, deleteLesson)
}
