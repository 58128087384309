import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '~core'
import { UserRanking } from '@teen-care/core'

export interface UserRankingState {
  currentPage: number
  loading: boolean
  totalPages: number
  userRankings: UserRanking[]
}

const initialState: UserRankingState = {
  currentPage: 1,
  loading: false,
  totalPages: 1,
  userRankings: [],
} as UserRankingState

const userRankingSlice = createSlice({
  initialState,
  name: 'userRanking',
  reducers: {
    removeUserRanking: (state, action: PayloadAction<string>) => {
      state.userRankings = state.userRankings.filter(
        (userRanking) => userRanking._id !== action.payload,
      )
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
    setUserRanking: (state, action: PayloadAction<UserRanking>) => {
      state.userRankings = state.userRankings.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setUserRankings: (state, action: PayloadAction<UserRanking[]>) => {
      state.userRankings = action.payload
    },
  },
})

export const userRankingAction = userRankingSlice.actions

export const userRankingState = (state: RootState) => state.userRanking

export const userRankingReducer = userRankingSlice.reducer
