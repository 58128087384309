import { lessonAction, userRoleState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { Lesson } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'

export const GET_LESSONS = 'GET_LESSONS'
export const LOAD_MORE_LESSONS = 'LOAD_MORE_LESSONS'

export const LIMIT_LESSON_ITEM = 0

const LearnRouteName = {
  lessonGets: '/get-lessons',
  route: '/learn-admin',
}

interface GetLessonsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getLessons({ onFailure, onSuccess, payload }: GetLessonsParams) {
  try {
    yield put(lessonAction.setLoading(true))
    yield put(lessonAction.setCurrentPage(1))
    yield put(lessonAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_LESSON_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.lessonGets}`,
    })
    yield put(lessonAction.setLessons(response?.data?.result?.lessons as Lesson[]))
    yield put(lessonAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(lessonAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(lessonAction.setLoading(false))
    onSuccess?.(response?.data?.result?.lessons)
  } catch (e: any) {
    yield put(lessonAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreLessons({ onFailure, onSuccess, payload }: GetLessonsParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(lessonAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_LESSON_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.lessonGets}`,
    })
    yield put(lessonAction.setLessons(response?.data?.result?.lessons as Lesson[]))
    yield put(lessonAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(lessonAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(lessonAction.setLoading(false))
    onSuccess?.(response?.data?.result?.lessons)
  } catch (e: any) {
    yield put(lessonAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* lessonSaga() {
  yield takeEvery(GET_LESSONS, getLessons)
  yield takeEvery(LOAD_MORE_LESSONS, loadMoreLessons)
}
