import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_GROUP_ITEM, REMOVE_FORUM_GROUP } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { CreateGroup } from './CreateGroup'
import { ForumGroup } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userBadgeState } from '~redux'

export function GroupItem({ index, item }: { index: number; item: ForumGroup }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(userBadgeState) || {}
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { _id, creatorId, groupName, profiles, updatedAt } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  function removeGroup(_id: string) {
    setShow(false)
    dispatch({
      payload: { _id },
      type: REMOVE_FORUM_GROUP,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_GROUP_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{groupName}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <CreateGroup content='Xem chi tiết' item={item} title='Sửa' />
          <Button className='m-1' variant='outline-danger' onClick={handleShow}>
            Xóa
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Xác nhận xóa Nhóm <span className='fw-bold'>{groupName}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant='danger' onClick={() => removeGroup(_id)}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
