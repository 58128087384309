import { apiPost, apiPostFormData } from '~core'
import { courseAction, courseState, userRoleState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { Course } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { toastServices } from '~utils'

export const GET_COURSES = 'GET_COURSES'
export const LOAD_MORE_COURSES = 'LOAD_MORE_COURSES'
export const CREATE_COURSE = 'CREATE_COURSE'
export const UPDATE_COURSE = 'UPDATE_COURSE'
export const DELETE_COURSE = 'DELETE_COURSE'
export const IMPORT_LEARN_DATA = 'IMPORT_LEARN_DATA'

export const LIMIT_COURSE_ITEM = 0

const LearnRouteName = {
  courseCreate: '/create-course',
  courseDelete: '/delete-course',
  courseGets: '/get-courses',
  courseUpdate: '/update-course',
  import: '/import',
  route: '/learn-admin',
}

interface GetCoursesParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getCourses({ onFailure, onSuccess, payload }: GetCoursesParams) {
  try {
    yield put(courseAction.setLoading(true))
    yield put(courseAction.setCurrentPage(1))
    yield put(courseAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_COURSE_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.courseGets}`,
    })
    yield put(courseAction.setCourses(response?.data?.result?.courses as Course[]))
    yield put(courseAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(courseAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.courses)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreCourses({ onFailure, onSuccess, payload }: GetCoursesParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(courseAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_COURSE_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.courseGets}`,
    })
    yield put(courseAction.setCourses(response?.data?.result?.courses as Course[]))
    yield put(courseAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(courseAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.courses)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

interface CreateCourseParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    courseDescription?: string
    courseName: string
    media?: any[]
  }
}
function* createCourse({ onFailure, onSuccess, payload }: CreateCourseParams) {
  try {
    const { courses } = yield select(courseState)
    yield put(courseAction.setLoading(true))
    const { courseDescription = '', courseName = '', media } = payload
    const params = new FormData()
    params.append('courseDescription', courseDescription)
    params.append('courseName', courseName)
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.courseCreate}`,
    })
    yield put(courseAction.setCourses([response?.data?.result?.course as Course, ...courses]))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.course)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateCourseParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    courseDescription?: string
    courseName: string
    media?: any[]
  }
}
function* updateCourse({ onFailure, onSuccess, payload }: UpdateCourseParams) {
  try {
    yield put(courseAction.setLoading(true))
    const { _id, courseDescription = '', courseName = '', media } = payload
    const params = new FormData()
    params.append('_id', _id)
    params.append('courseDescription', courseDescription)
    params.append('courseName', courseName)
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.courseUpdate}`,
    })
    yield put(courseAction.setCourse(response?.data?.result?.course as Course))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.course)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

interface DeleteCourseParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { courseId: string }
}
function* deleteCourse({ onFailure, onSuccess, payload }: DeleteCourseParams) {
  try {
    yield put(courseAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { _id: payload.courseId },
      url: `${LearnRouteName.route}${LearnRouteName.courseDelete}`,
    })
    yield put(courseAction.removeCourse(payload.courseId))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

interface ImportLearnParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { coursesData: any, lessonsData: any, media: any, questionsData: any }
}
function* importLearn({ onFailure, onSuccess, payload }: ImportLearnParams) {
  try {
    yield put(courseAction.setLoading(true))
    const { coursesData, lessonsData, media, questionsData } = payload
    toastServices.loading()
    const params = new FormData()
    coursesData && coursesData?.length && params.append('courseData', JSON.stringify(coursesData) as any)
    lessonsData && lessonsData?.length && params.append('lessonData', JSON.stringify(lessonsData) as any)
    questionsData && questionsData?.length && params.append('questionData', JSON.stringify(questionsData) as any)
    for (let i = 0; i < media.length; i++) {
      params.append('media', media[i])
    }
    yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.import}`,
    })
    yield put(courseAction.setLoading(false))
    toastServices.close()
    onSuccess?.()
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    toastServices.close()
    onFailure?.(e)
  }
}

export function* courseSaga() {
  yield takeEvery(GET_COURSES, getCourses)
  yield takeEvery(LOAD_MORE_COURSES, loadMoreCourses)
  yield takeEvery(CREATE_COURSE, createCourse)
  yield takeEvery(UPDATE_COURSE, updateCourse)
  yield takeEvery(DELETE_COURSE, deleteCourse)
  yield takeEvery(IMPORT_LEARN_DATA, importLearn)
}
