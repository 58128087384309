import { Button, Card, Form, InputGroup, Modal, Table } from 'react-bootstrap'
import { GET_COURSES, IMPORT_LEARN_DATA, LOAD_MORE_COURSES } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { CourseItem } from './components'
import { Paging } from '~components'
import Papa from 'papaparse'
import { courseState } from '~redux'
import { debounce } from 'lodash'

export function Course() {
  const dispatch = useAppDispatch()
  const { courses, currentPage, totalPages } = useAppSelector(courseState) || {}
  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)
  const [isShowImport, setShowImport] = useState<boolean>(false)
  const [coursesData, setCoursesData] = useState<any>()
  const [lessonsData, setLessonsData] = useState<any>()
  const [questionsData, setQuestionsData] = useState<any>()
  const [media, setMedia] = useState<any>()

  useEffect(() => {
    getCourses()
  }, [])

  useEffect(() => {
    const debouncedGetCourses = debounce(getCourses, 1000)
    debouncedGetCourses()
    return () => {
      debouncedGetCourses.cancel()
    }
  }, [search])

  function getCourses() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { search },
      type: GET_COURSES,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { page, search }, type: LOAD_MORE_COURSES })
  }

  const clearData = () => {
    setShowImport(false)
    setCoursesData(undefined)
    setLessonsData(undefined)
    setQuestionsData(undefined)
    setMedia(undefined)
  }

  const handleFileSelect = async (file: any, type: 'course' | 'lesson' | 'question') => {
    Papa.parse(file, {
      complete: ({ data }: any) => {
        const dataWithoutExample = data.slice(1)
        type === 'course' && setCoursesData(dataWithoutExample)
        type === 'lesson' && setLessonsData(dataWithoutExample)
        type === 'question' && setQuestionsData(dataWithoutExample)
      },
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
    })
  }

  function importLearn() {
    dispatch({
      onSuccess: () => {
        dispatch({
          onSuccess: () => clearData(),
          payload: { search },
          type: GET_COURSES,
        })
      },
      payload: { coursesData, lessonsData, media, questionsData },
      type: IMPORT_LEARN_DATA,
    })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Group className='d-flex align-items-center justify-content-between mb-3'>
        <Form.Label className='fw-bold'>Khóa học</Form.Label>
        <Button variant='dark' onClick={() => setShowImport(true)}>
          Import
        </Button>
      </Form.Group>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Label className='fw-bold'>Tìm kiếm</Form.Label>
        <Form.Control
          className='w-100'
          placeholder='Tìm kiếm'
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {['#', 'Tên', 'Mô tả', 'Ảnh', 'Thời gian', 'Thao tác'].map((item, index) => (
                  <th className='border-bottom' key={`${index}`}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {courses.map((item, index) => (
                <CourseItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>

      <Modal fullscreen show={isShowImport} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
              <Form.Label className='fw-bold m-1'>Nhập nội dung file như file mẫu</Form.Label>
              <Button
                href='https://docs.google.com/spreadsheets/d/1yBd5GFo1P_z4-kvSYv24uCnX45yJaJLd/edit?gid=634960887#gid=634960887'
                variant='primary'
              >
                File mẫu
              </Button>
            </Form.Group>

            <InputGroup className='mb-3'>
              <InputGroup.Text className='fw-bold'>Khóa học</InputGroup.Text>
              <Form.Control
                accept='.csv'
                placeholder='Chọn khóa học'
                type='file'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileSelect(event.target?.files?.[0], 'course')
                }
              />
            </InputGroup>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='fw-bold'>Bài học</InputGroup.Text>
              <Form.Control
                accept='.csv'
                placeholder='Chọn bài học'
                type='file'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileSelect(event.target?.files?.[0], 'lesson')
                }
              />
            </InputGroup>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='fw-bold'>Câu hỏi</InputGroup.Text>
              <Form.Control
                accept='.csv'
                placeholder='Chọn câu hỏi'
                type='file'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileSelect(event.target?.files?.[0], 'question')
                }
              />
            </InputGroup>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='fw-bold'>Hình ảnh</InputGroup.Text>
              <Form.Control
                multiple
                accept='image/jpg, image/jpeg, image/png, image/webp, image/tiff, image/gif, image/heic'
                type='file'
                onChange={(e: any) => setMedia(e?.target?.files)}
              />
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={importLearn}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
