import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_FORUM_TOPIC, GET_USER_ROLES, UPDATE_FORUM_TOPIC } from '~saga'
import { ForumTopic, UserRole } from '@teen-care/core'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { userRoleState } from '~redux'

export function CreateTopic({
  content,
  item,
  title,
}: {
  content: string
  item?: ForumTopic
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const { userRoles } = useAppSelector(userRoleState) || {}
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [media, setMedia] = useState<any | undefined>()
  const [hashTag, setHashTag] = useState<string>(item?.hashTag || '#')
  const [topicTitle, setTitle] = useState<string>(item?.title || '')
  const selectedRoleIds =
    userRoles
      ?.filter(
        (e) =>
          e.forumTopicIds?.length &&
          e.forumTopicIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1,
      )
      ?.map((e) => e._id) || []
  const [roleIds, setRoleIds] = useState<string[]>(selectedRoleIds || [])

  useEffect(() => {
    dispatch({ type: GET_USER_ROLES })
  }, [])

  const clearData = () => {
    setShowCreate(false)
    setMedia(undefined)
    setHashTag('#')
    setTitle('')
    setRoleIds([])
  }

  const handleShow = () => {
    setShowCreate(true)
    setMedia(undefined)
    isUpdate && setHashTag(item?.hashTag || '#')
    isUpdate && setTitle(item?.title || '')
    isUpdate && setRoleIds(selectedRoleIds || [])
  }

  function createTopic() {
    dispatch({
      onSuccess: () => {
        clearData()
        dispatch({ type: GET_USER_ROLES })
      },
      payload: {
        ...(isUpdate ? { _id: item?._id } : {}),
        hashTag,
        media,
        roleIds,
        title: topicTitle,
      },
      type: isUpdate ? UPDATE_FORUM_TOPIC : CREATE_FORUM_TOPIC,
    })
  }

  return (
    <>
      <Button className='m-1' variant='dark' onClick={handleShow}>
        {title}
      </Button>

      <Modal fullscreen show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Tên</Form.Label>
            <Form.Control
              placeholder='Nhập tên topic'
              value={topicTitle}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Hashtag</Form.Label>
            <Form.Control
              placeholder='Nhập hashtag topic'
              value={hashTag}
              onChange={(e) => setHashTag(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Hình ảnh</Form.Label>
            <Form.Control
              accept='image/jpg, image/jpeg, image/png, image/webp, image/tiff, image/gif, image/heic'
              type='file'
              onChange={(e: any) => setMedia(e?.target?.files[0])}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Vai trò</Form.Label>

            {userRoles.map((item: UserRole) => {
              const isChecked =
                roleIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
              return (
                <Form.Check
                  checked={isChecked}
                  className='mb-1'
                  key={item?._id}
                  label={item?.userRoleName}
                  onChange={(e: any) => {
                    setRoleIds((current) =>
                      e?.target?.checked
                        ? [item?._id, ...current]
                        : current?.filter((e) => e !== item?._id),
                    )
                  }}
                />
              )
            })}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createTopic}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
