import '~assets/style/Post.css'

import { DELETE_LESSON, LIMIT_POST_ITEM } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { Button } from 'react-bootstrap'
import { Lesson } from '@teen-care/core'
import { courseState } from '~redux'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export function LessonItem({ index, item }: { index: number; item: Lesson }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(courseState) || {}
  const navigate = useNavigate()
  const { createdAt, lessonDescription, lessonName, lessonSubDescription, score } = item || {}

  function gotoQuestion() {
    navigate(`/learn/question/${item._id}`)
  }

  function deleteItem(e: React.MouseEvent) {
    e.stopPropagation()
    dispatch({
      payload: { courseId: item.courseIds[0], lessonId: item._id },
      type: DELETE_LESSON,
    })
  }

  return (
    <tr onClick={gotoQuestion}>
      <td className='align-middle'>
        <span className='fw-normal'>{(currentPage - 1) * LIMIT_POST_ITEM + index + 1}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{lessonName}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{lessonDescription}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{lessonSubDescription}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{score}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>
          {createdAt ? dayjs(createdAt).format('HH:mm DD/MM/YYYY') : '...'}
        </span>
      </td>
      <td className='align-middle'>
        <Button variant='danger' onClick={deleteItem}>
          Xóa
        </Button>
      </td>
    </tr>
  )
}
