import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '~core'
import { UserScore } from '@teen-care/core'

export interface UserScoreState {
  currentPage: Record<string, number>
  loading: Record<string, boolean>
  totalPages: Record<string, number>
  userScores: Record<string, UserScore[]>
}

const initialState: UserScoreState = {
  currentPage: {},
  loading: {},
  totalPages: {},
  userScores: {},
} as UserScoreState

const userScoreSlice = createSlice({
  initialState,
  name: 'userScore',
  reducers: {
    removeUserScore: (state, action: PayloadAction<{ userId: string; userScoreId: string }>) => {
      state.userScores = {
        ...state.userScores,
        [action.payload.userId]: state.userScores?.[action.payload.userId].filter(
          (userScore) => userScore._id !== action.payload?.userScoreId,
        ),
      }
    },
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number; userId: string }>) => {
      state.currentPage = {
        ...state.currentPage,
        [action.payload.userId]: action.payload.currentPage,
      }
    },
    setLoading: (state, action: PayloadAction<{ loading: boolean; userId: string }>) => {
      state.loading = {
        ...state.loading,
        [action.payload.userId]: action.payload.loading,
      }
    },
    setTotalPages: (state, action: PayloadAction<{ totalPages: number; userId: string }>) => {
      state.totalPages = {
        ...state.currentPage,
        [action.payload.userId]: action.payload.totalPages,
      }
    },
    setUserScore: (state, action: PayloadAction<{ userId: string; userScore: UserScore }>) => {
      state.userScores = {
        ...state.userScores,
        [action.payload.userId]: state.userScores?.[action.payload.userId]?.length
          ? state.userScores?.[action.payload.userId]?.map((item) => {
              if (item?._id === action?.payload?.userScore?._id) {
                return {
                  ...item,
                  ...action?.payload?.userScore,
                }
              }
              return item
            })
          : [action?.payload?.userScore],
      }
    },
    setUserScores: (state, action: PayloadAction<{ userId: string; userScores: UserScore[] }>) => {
      state.userScores = {
        ...state.userScores,
        [action.payload.userId]: action.payload.userScores,
      }
    },
  },
})

export const userScoreAction = userScoreSlice.actions

export const userScoreState = (state: RootState) => state.userScore

export const userScoreReducer = userScoreSlice.reducer
