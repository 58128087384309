import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_USER_RANKING, UPDATE_USER_RANKING } from '~saga'

import { UserRanking } from '@teen-care/core'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function CreateUserRanking({
  content,
  item,
  title,
}: {
  content: string
  item?: UserRanking
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [rankName, setRankName] = useState<string>(item?.rankName || '')
  const [score, setScore] = useState<string>(item?.score?.toString() || '')

  const clearData = () => {
    setShowCreate(false)
    setRankName('')
    setScore('')
  }

  const handleShow = () => {
    setShowCreate(true)
    item && setRankName(item?.rankName || '')
    item && setScore(item?.score?.toString() || '')
  }

  function createUserRanking() {
    dispatch({
      onSuccess: clearData,
      payload: isUpdate
        ? { _id: item?._id, rankName, score: parseFloat(score) }
        : { rankName, score: parseFloat(score) },
      type: isUpdate ? UPDATE_USER_RANKING : CREATE_USER_RANKING,
    })
  }

  return (
    <>
      <Button className='m-1' variant='dark' onClick={handleShow}>
        {title}
      </Button>

      <Modal show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Tên</Form.Label>
            <Form.Control
              placeholder='Nhập tên cấp độ'
              value={rankName}
              onChange={(e) => setRankName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Điểm</Form.Label>
            <Form.Control
              placeholder='Nhập điểm'
              value={score}
              onChange={(e) => setScore(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createUserRanking}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
