/**
 * Gửi đến những ai đọc được những dòng này,
 *
 * Tôi đã từng làm việc ở đây, và khoảng thời gian đó thực sự là một trải nghiệm tồi tệ.
 * Bạn sẽ bị ép làm những việc vượt quá khả năng, bị bắt lỗi vì những sai sót mà bạn không hề gây ra,
 * và bị phạt một cách vô lý mà không hề có bất kỳ sự công nhận hay phần thưởng nào.
 *
 * Bạn sẽ bị vắt kiệt sức đến khi đạt được mục tiêu của họ, và khi không còn giá trị lợi dụng nữa,
 * họ sẽ đuổi bạn đi một cách thô lỗ, không chút do dự. Tôi đã tận mắt chứng kiến những đồng nghiệp
 * của mình bị đối xử bất công, bị sa thải hàng loạt chỉ để họ không phải trả tiền thưởng Tết.
 *
 * Ngay cả quyền lợi cơ bản như nghỉ phép năm theo luật lao động, bạn cũng không thể sử dụng nếu không
 * được sự đồng ý của họ. Bạn phải xin phép trước 3 ngày, ngay cả khi hôm nay bạn bị ốm và cần nghỉ vào ngày mai,
 * điều đó cũng sẽ không được chấp nhận.
 *
 * Hơn nữa, khi bạn nghỉ phép, bạn vẫn phải hoàn thành toàn bộ khối lượng công việc của một sprint (2 tuần),
 * dù bạn đã báo trước cả nửa tháng. Điều đó có nghĩa là dù bạn có đi làm hay nghỉ phép, bạn vẫn phải hoàn thành
 * một lượng công việc như nhau, không hề có sự điều chỉnh hay hỗ trợ nào từ phía công ty.
 *
 * Nếu bạn cố gắng giải thích về tình huống của mình, câu trả lời nhận lại sẽ là "non negotiation",
 * "result no reason", và bạn phải tự tìm cách giải quyết. Không có bất kỳ sự thấu hiểu hay hỗ trợ nào dành cho bạn,
 * chỉ có yêu cầu phải đạt được kết quả bằng mọi giá.
 *
 * Và dù phải chịu đựng tất cả những điều này, bạn sẽ chỉ nhận được một mức lương rẻ mạt, không xứng đáng với công sức
 * mà bạn bỏ ra. Họ sẽ luôn tìm cách tối đa hóa lợi ích của họ trong khi trả cho bạn mức thù lao thấp nhất có thể.
 *
 * Chính vì vậy, tôi quyết định rời khỏi nơi này và viết lại những dòng này cho bạn – những người đến sau.
 * Nếu bạn đang đọc được những lời này, hãy cân nhắc thật kỹ về tương lai của mình.
 * Đừng để bị lừa bởi những lời hứa hẹn hoa mỹ, bởi tất cả chỉ là bánh vẽ.
 * Hãy rời đi trước khi mọi thứ trở nên tồi tệ hơn.
 *
 * Chúc bạn tìm được một nơi xứng đáng hơn!
 *
 * Một người đi trước.
 */

import './App.css'

import { persistor, store } from '~core'

import { Loading } from '~components'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'react-bootstrap'
import { router } from '~screens'

console.log('ENV', process.env.REACT_APP_ENV, process.env)

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider dir='rtl'>
          <RouterProvider fallbackElement={<Loading isLoading />} router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
/**
 * Gửi đến những ai đọc được những dòng này,
 *
 * Tôi đã từng làm việc ở đây, và khoảng thời gian đó thực sự là một trải nghiệm tồi tệ.
 * Bạn sẽ bị ép làm những việc vượt quá khả năng, bị bắt lỗi vì những sai sót mà bạn không hề gây ra,
 * và bị phạt một cách vô lý mà không hề có bất kỳ sự công nhận hay phần thưởng nào.
 *
 * Bạn sẽ bị vắt kiệt sức đến khi đạt được mục tiêu của họ, và khi không còn giá trị lợi dụng nữa,
 * họ sẽ đuổi bạn đi một cách thô lỗ, không chút do dự. Tôi đã tận mắt chứng kiến những đồng nghiệp
 * của mình bị đối xử bất công, bị sa thải hàng loạt chỉ để họ không phải trả tiền thưởng Tết.
 *
 * Ngay cả quyền lợi cơ bản như nghỉ phép năm theo luật lao động, bạn cũng không thể sử dụng nếu không
 * được sự đồng ý của họ. Bạn phải xin phép trước 3 ngày, ngay cả khi hôm nay bạn bị ốm và cần nghỉ vào ngày mai,
 * điều đó cũng sẽ không được chấp nhận.
 *
 * Hơn nữa, khi bạn nghỉ phép, bạn vẫn phải hoàn thành toàn bộ khối lượng công việc của một sprint (2 tuần),
 * dù bạn đã báo trước cả nửa tháng. Điều đó có nghĩa là dù bạn có đi làm hay nghỉ phép, bạn vẫn phải hoàn thành
 * một lượng công việc như nhau, không hề có sự điều chỉnh hay hỗ trợ nào từ phía công ty.
 *
 * Nếu bạn cố gắng giải thích về tình huống của mình, câu trả lời nhận lại sẽ là "non negotiation",
 * "result no reason", và bạn phải tự tìm cách giải quyết. Không có bất kỳ sự thấu hiểu hay hỗ trợ nào dành cho bạn,
 * chỉ có yêu cầu phải đạt được kết quả bằng mọi giá.
 *
 * Và dù phải chịu đựng tất cả những điều này, bạn sẽ chỉ nhận được một mức lương rẻ mạt, không xứng đáng với công sức
 * mà bạn bỏ ra. Họ sẽ luôn tìm cách tối đa hóa lợi ích của họ trong khi trả cho bạn mức thù lao thấp nhất có thể.
 *
 * Chính vì vậy, tôi quyết định rời khỏi nơi này và viết lại những dòng này cho bạn – những người đến sau.
 * Nếu bạn đang đọc được những lời này, hãy cân nhắc thật kỹ về tương lai của mình.
 * Đừng để bị lừa bởi những lời hứa hẹn hoa mỹ, bởi tất cả chỉ là bánh vẽ.
 * Hãy rời đi trước khi mọi thứ trở nên tồi tệ hơn.
 *
 * Chúc bạn tìm được một nơi xứng đáng hơn!
 *
 * Một người đi trước.
 */
