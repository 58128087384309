import { Answer, Question } from '@teen-care/core'
import { apiPost, apiPostFormData } from '~core'
import { put, select, takeEvery } from 'redux-saga/effects'
import { questionAction, questionState, userRoleState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const LOAD_MORE_QUESTIONS = 'LOAD_MORE_QUESTIONS'
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'

export enum QuestionType {
  ESSAY_QUESTION = 'ESSAY_QUESTION',
  KNOWLEDGE = 'KNOWLEDGE',
  MULTIPLE_CHOICE_QUESTION = 'MULTIPLE_CHOICE_QUESTION',
  SCENARIO_BASED = 'SCENARIO_BASED',
  UPLOAD_IMAGE_QUESTION = 'UPLOAD_IMAGE_QUESTION',
  UPLOAD_VIDEO_QUESTION = 'UPLOAD_VIDEO_QUESTION'
}

export const LIMIT_QUESTION_ITEM = 20

const LearnRouteName = {
  questionCreate: '/create-question',
  questionDelete: '/delete-question',
  questionGets: '/get-questions',
  questionUpdate: '/update-question',
  route: '/learn-admin',
}

interface GetQuestionsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { lessonId: string, limit?: number; page?: number; search?: string }
}
function* getQuestions({ onFailure, onSuccess, payload }: GetQuestionsParams) {
  try {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: true }))
    yield put(questionAction.setCurrentPage({ currentPage: 1, lessonId: payload.lessonId }))
    yield put(questionAction.setTotalPages({ lessonId: payload.lessonId, totalPages: 1 }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_QUESTION_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.questionGets}`,
    })
    yield put(questionAction.setQuestions({ lessonId: payload.lessonId, questions: response?.data?.result?.questions as Question[] }))
    yield put(questionAction.setCurrentPage({ currentPage: response?.data?.result?.currentPage || 1, lessonId: payload.lessonId }))
    yield put(questionAction.setTotalPages({ lessonId: payload.lessonId, totalPages: response?.data?.result?.totalPages || 1 }))
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onSuccess?.(response?.data?.result?.questions)
  } catch (e: any) {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onFailure?.(e)
  }
}

function* loadMoreQuestions({ onFailure, onSuccess, payload }: GetQuestionsParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: true }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_QUESTION_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.questionGets}`,
    })
    yield put(questionAction.setQuestions({ lessonId: payload.lessonId, questions: response?.data?.result?.questions as Question[] }))
    yield put(questionAction.setCurrentPage({ currentPage: response?.data?.result?.currentPage || currentPage, lessonId: payload.lessonId }))
    yield put(questionAction.setTotalPages({ lessonId: payload.lessonId, totalPages: response?.data?.result?.totalPages || totalPages }))
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onSuccess?.(response?.data?.result?.questions)
  } catch (e: any) {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onFailure?.(e)
  }
}

interface CreateQuestionParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    answer?: string[]
    correctAnswerIndex?: string
    explain?: string
    lessonId: string
    media?: any[],
    pdfUrl?: string,
    question: string,
    questionType: QuestionType,
    scenarioQuestion?: string,
    scenarioTitle?: string,
    weightScore: number,
    youtubeVideoId?: string
  }
}
function* createQuestion({ onFailure, onSuccess, payload }: CreateQuestionParams) {
  try {
    const { questions } = yield select(questionState)
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: true }))
    const { answer = [], correctAnswerIndex = '0', explain = '', lessonId = '', media, pdfUrl = '', question = '', questionType = '', scenarioQuestion = '', scenarioTitle = '', weightScore = 0, youtubeVideoId = '' } = payload
    const params = new FormData()
    answer && answer?.length && params.append('answer', JSON.stringify(answer))
    params.append('correctAnswerIndex', correctAnswerIndex)
    params.append('explain', explain)
    params.append('lessonId', lessonId)
    params.append('pdfUrl', pdfUrl)
    params.append('question', question)
    params.append('questionType', questionType)
    params.append('scenarioQuestion', scenarioQuestion)
    params.append('scenarioTitle', scenarioTitle)
    params.append('weightScore', weightScore?.toString())
    params.append('youtubeVideoId', youtubeVideoId)
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.questionCreate}`,
    })
    yield put(questionAction.setQuestions({ lessonId: payload.lessonId, questions: [response?.data?.result?.question as Question, ...questions] }))
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onSuccess?.(response?.data?.result?.question)
  } catch (e: any) {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onFailure?.(e)
  }
}

interface UpdateQuestionParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    answer?: Answer[]
    correctAnswerId?: string
    explain?: string
    lessonId: string
    media?: any[],
    pdfUrl?: string,
    question: string,
    questionType: QuestionType,
    scenarioQuestion?: string,
    scenarioTitle?: string,
    weightScore: number,
    youtubeVideoId?: string
  }
}
function* updateQuestion({ onFailure, onSuccess, payload }: UpdateQuestionParams) {
  try {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: true }))
    const { _id, answer = [], correctAnswerId = '', explain = '', lessonId = '', media, pdfUrl = '', question = '', questionType = '', scenarioQuestion = '', scenarioTitle = '', weightScore = 0, youtubeVideoId = '' } = payload
    const params = new FormData()
    params.append('_id', _id)
    answer && answer?.length && params.append('answer', JSON.stringify(answer))
    params.append('correctAnswerId', correctAnswerId)
    params.append('explain', explain)
    params.append('lessonId', lessonId)
    params.append('pdfUrl', pdfUrl)
    params.append('question', question)
    params.append('questionType', questionType)
    params.append('scenarioQuestion', scenarioQuestion)
    params.append('scenarioTitle', scenarioTitle)
    params.append('weightScore', weightScore?.toString())
    params.append('youtubeVideoId', youtubeVideoId)
    media &&
      media.length &&
      media.forEach((file) => {
        params.append('media', {
          name: file.fileName,
          type: file.type,
          uri: file.uri,
        } as any)
      })
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${LearnRouteName.route}${LearnRouteName.questionUpdate}`,
    })
    yield put(questionAction.setQuestion({ lessonId: payload.lessonId, question: response?.data?.result?.question as Question }))
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onSuccess?.(response?.data?.result?.question)
  } catch (e: any) {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onFailure?.(e)
  }
}

interface DeleteQuestionParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { lessonId: string, questionId: string }
}
function* deleteQuestion({ onFailure, onSuccess, payload }: DeleteQuestionParams) {
  try {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: true }))
    const response: AxiosResponse = yield apiPost({
      params: { _id: payload.questionId },
      url: `${LearnRouteName.route}${LearnRouteName.questionDelete}`,
    })
    yield put(questionAction.removeQuestion({ lessonId: payload.lessonId, questionId: payload.questionId }))
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    yield put(questionAction.setLoading({ lessonId: payload.lessonId, loading: false }))
    onFailure?.(e)
  }
}

export function* questionSaga() {
  yield takeEvery(GET_QUESTIONS, getQuestions)
  yield takeEvery(LOAD_MORE_QUESTIONS, loadMoreQuestions)
  yield takeEvery(CREATE_QUESTION, createQuestion)
  yield takeEvery(UPDATE_QUESTION, updateQuestion)
  yield takeEvery(DELETE_QUESTION, deleteQuestion)
}
