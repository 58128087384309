import { all, fork } from 'redux-saga/effects'
import {
  commentSaga,
  courseSaga,
  forumGroupSaga,
  forumTopicSaga,
  lessonSaga,
  notificationAdminSaga,
  postHistorySaga,
  postSaga,
  questionSaga,
  userBadgeSaga,
  userProfileSaga,
  userRankingSaga,
  userRoleSaga,
  userSaga,
  userScoreSaga
} from '~saga'

export function* rootSaga() {
  yield all([
    fork(commentSaga),
    fork(postSaga),
    fork(postHistorySaga),
    fork(userBadgeSaga),
    fork(userProfileSaga),
    fork(forumTopicSaga),
    fork(userRankingSaga),
    fork(userSaga),
    fork(userScoreSaga),
    fork(userRoleSaga),
    fork(forumGroupSaga),
    fork(courseSaga),
    fork(lessonSaga),
    fork(questionSaga),
    fork(notificationAdminSaga),
  ])
}
