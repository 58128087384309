import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Comment } from '@teen-care/core'
import type { RootState } from '~core'

export interface CommentState {
  comments: Record<string, Comment[]>
  loadMore: Record<string, boolean>
  loading: Record<string, boolean>
}

const initialState: CommentState = {
  comments: {},
  loadMore: {},
  loading: {},
} as CommentState

const commentSlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    setComments: (state, action: PayloadAction<{ comments: Comment[]; postId: string }>) => {
      state.comments = {
        ...state.comments,
        [action.payload.postId]: action.payload.comments,
      }
    },
    setLoadMore: (state, action: PayloadAction<{ loadMore: boolean; postId: string }>) => {
      state.loadMore = {
        ...state.loadMore,
        [action.payload.postId]: action.payload.loadMore,
      }
    },
    setLoading: (state, action: PayloadAction<{ loading: boolean; postId: string }>) => {
      state.loading = {
        ...state.loading,
        [action.payload.postId]: action.payload.loading,
      }
    },
  },
})

export const commentAction = commentSlice.actions

export const commentState = (state: RootState) => state.comment

export const commentReducer = commentSlice.reducer
