import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_USER_BADGE, UPDATE_USER_BADGE } from '~saga'

import { UserBadge } from '@teen-care/core'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function CreateUserBadge({
  content,
  item,
  title,
}: {
  content: string
  item?: UserBadge
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [media, setMedia] = useState<any | undefined>()
  const [userBadgeColor, setUserBadgeColor] = useState<string>(item?.userBadgeColor || '#000000')
  const [userBadgeName, setUserBadgeName] = useState<string>(item?.userBadgeName || '')
  const [userBadgeNameColor, setUserBadgeNameColor] = useState<string>(
    item?.userBadgeNameColor || '#FFFFFF',
  )

  const clearData = () => {
    setShowCreate(false)
    setMedia(undefined)
    setUserBadgeColor('#000000')
    setUserBadgeName('')
    setUserBadgeNameColor('#FFFFFF')
  }

  const handleShow = () => {
    setShowCreate(true)
    setMedia(undefined)
    item && setUserBadgeColor(item?.userBadgeColor || '')
    item && setUserBadgeName(item?.userBadgeName || '')
    item && setUserBadgeNameColor(item?.userBadgeNameColor || '')
  }

  function createUserBadge() {
    dispatch({
      onSuccess: clearData,
      payload: {
        ...(isUpdate ? { _id: item?._id } : {}),
        media,
        userBadgeColor,
        userBadgeName,
        userBadgeNameColor,
      },
      type: isUpdate ? UPDATE_USER_BADGE : CREATE_USER_BADGE,
    })
  }

  return (
    <>
      <Button className='m-1' variant='dark' onClick={handleShow}>
        {title}
      </Button>

      <Modal fullscreen show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Tên</Form.Label>
            <Form.Control
              maxLength={20}
              placeholder='Nhập tên huy hiệu'
              value={userBadgeName}
              onChange={(e) => setUserBadgeName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Hình ảnh</Form.Label>
            <Form.Control
              accept='image/jpg, image/jpeg, image/png, image/webp, image/tiff, image/gif, image/heic'
              type='file'
              onChange={(e: any) => setMedia(e?.target?.files[0])}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Màu nền</Form.Label>
            <Form.Control
              className='w-100'
              type='color'
              value={userBadgeColor}
              onChange={(e: any) => setUserBadgeColor(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Màu chữ</Form.Label>
            <Form.Control
              className='w-100'
              type='color'
              value={userBadgeNameColor}
              onChange={(e: any) => setUserBadgeNameColor(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Xem trước hình ảnh huy hiệu</Form.Label>
            {userBadgeColor !== '' && (
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: userBadgeColor,
                  borderRadius: 20,
                  flexDirection: 'row',
                  padding: '4px 12px',
                }}
              >
                {media !== undefined && (
                  <img
                    src={URL.createObjectURL(media) || ''}
                    style={{ height: 10, marginRight: 5, width: 10 }}
                  />
                )}
                <span className='fw-normal' style={{ color: userBadgeNameColor }}>
                  {userBadgeName || 'Tên huy hiệu'}
                </span>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createUserBadge}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
