import { apiPost, apiPostFormData } from '~core'
import { put, select, takeEvery } from 'redux-saga/effects'
import { topicAction, topicState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { Topic } from '@teen-care/core'

export const GET_TOPICS = 'GET_TOPICS'
export const LOAD_MORE_TOPICS = 'LOAD_MORE_TOPICS'
export const GET_TOPIC = 'GET_TOPIC'
export const CREATE_TOPIC = 'CREATE_TOPIC'
export const UPDATE_TOPIC = 'UPDATE_TOPIC'
export const REMOVE_TOPIC = 'REMOVE_TOPIC'

export const LIMIT_TOPIC_ITEM = 10

const forumAdminRouteName = {
  route: '/forum-admin',
  topicCreate: '/create-topic',
  topicGet: '/get-topic',
  topicGets: '/get-topics',
  topicRemove: '/remove-topic',
  topicUpdate: '/update-topic',
}

interface CreateTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    hashTag: string
    media?: any
    title: string
  }
}
function* createTopic({ onFailure, onSuccess, payload }: CreateTopicParams) {
  try {
    const { hashTag, media, title } = payload || {}
    const params = new FormData()
    params.append('hashTag', hashTag)
    params.append('title', title)
    media && params.append('media', media)

    yield put(topicAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicCreate}`,
    })
    const { topics } = yield select(topicState)
    yield put(
      topicAction.setTopics([response?.data?.result?.topic as Topic, ...topics] as Array<Topic>),
    )
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topic)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface UpdateTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    hashTag: string
    media?: any
    title: string
  }
}
function* updateTopic({ onFailure, onSuccess, payload }: UpdateTopicParams) {
  try {
    const { _id, hashTag, media, title } = payload || {}
    const params = new FormData()
    params.append('_id', _id)
    params.append('hashTag', hashTag)
    params.append('title', title)
    media && params.append('media', media)
    yield put(topicAction.setLoading(true))
    const response: AxiosResponse = yield apiPostFormData({
      params,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicUpdate}`,
    })
    yield put(topicAction.setTopic(response?.data?.result?.topic as Topic))
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topic)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface RemoveTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* removeTopic({ onFailure, onSuccess, payload }: RemoveTopicParams) {
  try {
    yield put(topicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicRemove}`,
    })
    yield put(topicAction.removeTopic(response?.data?.result?.topic?._id))
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topic)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetTopicsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getTopics({ onFailure, onSuccess, payload }: GetTopicsParams) {
  try {
    yield put(topicAction.setLoading(true))
    yield put(topicAction.setCurrentPage(1))
    yield put(topicAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_TOPIC_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGets}`,
    })
    yield put(topicAction.setTopics(response?.data?.result?.topics as Array<Topic>))
    yield put(topicAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(topicAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topics)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreTopics({ onFailure, onSuccess, payload }: GetTopicsParams) {
  try {
    const { currentPage, totalPages } = yield select(topicState)
    yield put(topicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_TOPIC_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGets}`,
    })
    yield put(topicAction.setTopics(response?.data?.result?.topics as Array<Topic>))
    yield put(topicAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(topicAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topics)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetTopicParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getTopic({ onFailure, onSuccess, payload }: GetTopicParams) {
  try {
    yield put(topicAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.topicGet}`,
    })
    yield put(topicAction.setTopic(response?.data?.result?.topic as Topic))
    yield put(topicAction.setLoading(false))
    onSuccess?.(response?.data?.result?.topic)
  } catch (e: any) {
    yield put(topicAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* topicSaga() {
  yield takeEvery(GET_TOPIC, getTopic)
  yield takeEvery(GET_TOPICS, getTopics)
  yield takeEvery(LOAD_MORE_TOPICS, loadMoreTopics)
  yield takeEvery(CREATE_TOPIC, createTopic)
  yield takeEvery(UPDATE_TOPIC, updateTopic)
  yield takeEvery(REMOVE_TOPIC, removeTopic)
}
