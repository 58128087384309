import * as XLSX from 'xlsx'

import { Button, Form, Modal } from 'react-bootstrap'

import { CREATE_MANY_USER } from '~saga'
import Papa from 'papaparse'
import { UserProfile } from '@teen-care/core'
import dayjs from 'dayjs'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function ImportListUser({
  content,
  textOnly,
  title,
}: {
  content: string
  item?: UserProfile
  textOnly?: boolean
  title: string
}) {
  const [showForm, setShowForm] = useState<boolean>(false)
  const [userData, setUserData] = useState<Array<Record<string, string>>>([])
  const [error, setError] = useState<string>('')

  const dispatch = useAppDispatch()

  const clearData = () => {
    setShowForm(false)
    setUserData([])
    setError('')
  }

  const handleShow = () => {
    setShowForm(true)
  }

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0]

    if (!file) return

    const fileExtension = file.name.split('.').pop()?.toLowerCase()

    // Xử lý tệp CSV/TSV
    if (fileExtension === 'csv' || fileExtension === 'tsv') {
      const delimiter = fileExtension === 'csv' ? ',' : '\t'
      Papa.parse(file, {
        complete: ({ data }: { data: any[] }) => {
          const formattedData = data.map((row) => {
            delete row.userRole
            return {
              ...row,
              dob:
                row.dob && dayjs(row.dob, 'YYYY-MM-DD', true).isValid()
                  ? dayjs(row.dob).valueOf()
                  : null,
            }
          })
          setUserData(formattedData)
        },
        delimiter,
        header: true,
        skipEmptyLines: true,
      }) // Xử lý tệp Excel
    } else if (fileExtension === 'xlsx') {
      const fileContent = await file.arrayBuffer()
      const workbook = XLSX.read(fileContent, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]

      const jsonData = XLSX.utils.sheet_to_json<Record<string, any>>(worksheet, { header: 1 })
      if (jsonData.length > 0) {
        const [columns, ...rows] = jsonData
        const formattedData = rows.map((row) =>
          columns.reduce(
            (acc: any, col: any, index: number) => {
              const value = row[index]
              if (col.trim() === 'userRole') return acc
              if (col.trim() === 'dob') {
                const dobTimestamp = Number(value)
                acc[col.trim()] = dayjs(dobTimestamp).isValid() ? dobTimestamp : null
              } else {
                acc[col.trim()] = value || ''
              }
              return acc
            },
            {} as Record<string, any>,
          ),
        )
        setUserData(formattedData)
      }
    } else {
      setError('Loại file này không được hỗ trợ. Vui lòng tải lên file có đuôi .csv, .tsv , .xlsx')
    }
  }

  function createManyUser() {
    dispatch({
      onSuccess: clearData,
      payload: { users: userData },
      type: CREATE_MANY_USER,
    })
  }

  return (
    <>
      {textOnly ? (
        <div onClick={handleShow}>{title}</div>
      ) : (
        <Button className='m-1' variant='dark' onClick={handleShow}>
          {title}
        </Button>
      )}

      <Modal fullscreen show={showForm} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Chọn file</Form.Label>
            <Form.Control accept='.csv, .tsv, .xlsx' type='file' onChange={handleFileSelect} />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Nhập nội dung file như file mẫu</Form.Label>
            <Button
              href='https://docs.google.com/spreadsheets/d/14AGDHqxQH-RGtwoJLfLqVFVxsYcWO61_I89NCXWPNcM/edit?usp=sharing'
              variant='primary'
            >
              File mẫu
            </Button>
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>
              Khuyến khích sao chép và chỉnh sửa file mẫu trên Google Sheet để dữ liệu đầu vào chính
              xác nhất
            </Form.Label>
          </Form.Group>
          {error !== '' && (
            <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
              <Form.Label className='fw-bold m-1'>{error}</Form.Label>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createManyUser}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
