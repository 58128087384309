import { Button, Form, Modal } from 'react-bootstrap'
import { SET_USER_BADGE, UNSET_USER_BADGE } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { UserProfile } from '@teen-care/core'
import { useState } from 'react'
import { userBadgeState } from '~redux'

export function UpdateUserBadge({
  content,
  item,
  textOnly,
  title,
}: {
  content: string
  item?: UserProfile
  textOnly?: boolean
  title: string
}) {
  const dispatch = useAppDispatch()
  const { userBadges } = useAppSelector(userBadgeState)
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [selectedBadgeId, setSelectedBadgeId] = useState<string>(item?.userBadge?._id || '')

  const userBadgeName =
    userBadges?.find((e) => e._id === selectedBadgeId)?.userBadgeName || 'Chọn huy hiệu'

  const clearData = () => {
    setShowCreate(false)
  }

  const handleShow = () => {
    setShowCreate(true)
  }

  function setUserBadge() {
    dispatch({
      onSuccess: clearData,
      payload: selectedBadgeId?.length
        ? { userBadgeId: selectedBadgeId, userId: item?._id }
        : { userId: item?._id },
      type: selectedBadgeId?.length ? SET_USER_BADGE : UNSET_USER_BADGE,
    })
  }

  return (
    <>
      {textOnly ? (
        <div onClick={handleShow}>{title}</div>
      ) : (
        <Button className='m-1' variant='dark' onClick={handleShow}>
          {title}
        </Button>
      )}

      <Modal show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='d-flex align-items-center mb-2'>
            <Form.Label className='fw-bold m-1'>Người dùng</Form.Label>
            <Form.Label className='m-1'>{item?.name}</Form.Label>
          </Form.Group>
          <Form.Group className='d-flex align-items-center mb-2'>
            <Form.Label className='fw-bold m-1'>Giới tính</Form.Label>
            <Form.Select onChange={(e: any) => setSelectedBadgeId(e.target.value)}>
              <option>{userBadgeName}</option>
              {userBadges?.map((item, index) => (
                <option key={index} value={item?._id}>
                  {item?.userBadgeName}
                </option>
              ))}
              <option value={''}>Xóa huy hiệu</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={setUserBadge}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
