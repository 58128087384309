import { Card, Form, Table } from 'react-bootstrap'
import { CreateGroup, GroupItem } from './components'
import { GET_FORUM_GROUPS, LOAD_MORE_FORUM_GROUPS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { Paging } from '~components'
import { debounce } from 'lodash'
import { forumGroupState } from '~redux'

export function ForumGroup() {
  const dispatch = useAppDispatch()
  const { currentPage, forumGroups, totalPages } = useAppSelector(forumGroupState) || {}
  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)

  useEffect(() => {
    getGroups()
  }, [])

  useEffect(() => {
    const debouncedGetPosts = debounce(getGroups, 1000)
    debouncedGetPosts()
    return () => {
      debouncedGetPosts.cancel()
    }
  }, [search])

  function getGroups() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { search },
      type: GET_FORUM_GROUPS,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { page, search }, type: LOAD_MORE_FORUM_GROUPS })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Danh sách Nhóm</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Control
          className='w-75'
          placeholder='Tìm theo tên'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CreateGroup content='Thêm Nhóm' title='Thêm Nhóm' />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {['#', 'Tên Nhóm', 'Thời gian cập nhật', 'Người cập nhật', 'Thao tác'].map(
                  (item, index) => (
                    <th className='border-bottom' key={`${index}`}>
                      {item}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {forumGroups.map((item, index) => (
                <GroupItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
