import '~assets/style/Sidebar.css'

import { Button, Image } from 'react-bootstrap'
import { FaBars, FaBookOpen, FaDisease, FaRegUserCircle } from 'react-icons/fa'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'

import { CiCircleChevRight } from 'react-icons/ci'
import { LOGOUT } from '~saga'
import { Link } from 'react-router-dom'
import LogoImage from '~assets/image/logo.png'
import { MdForum } from 'react-icons/md'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function SidebarMenu() {
  const [collapsed, setCollapsed] = useState(false)
  const dispatch = useAppDispatch()
  const toggleSidebar = () => {
    setCollapsed((current) => !current)
  }

  const sideBarData = [
    {
      icon: <FaRegUserCircle className='sub-menu-icon' />,
      label: 'Quản lý người dùng',
      menu: [
        { label: 'Danh sách người dùng', link: '/users' },
        { label: 'Vai trò người dùng', link: '/users/role' },
        { label: 'Tạo thông báo', link: '/users/notifications' },
      ],
    },
    {
      icon: <MdForum className='sub-menu-icon' />,
      label: 'Cộng đồng',
      menu: [
        { label: 'Bài viết', link: '/forum/posts' },
        { label: 'Topic', link: '/forum/topic' },
        { label: 'Huy hiệu', link: '/forum/badge' },
        { label: 'Nhóm', link: '/forum/group' },
      ],
    },
    {
      icon: <FaBookOpen className='sub-menu-icon' />,
      label: 'Học tập',
      menu: [{ label: 'Khóa học', link: '/learn/course' }],
    },
    {
      icon: <FaDisease className='sub-menu-icon' />,
      label: 'Điểm TeenCare',
      menu: [{ label: 'Cài đặt cấp độ', link: '/user/ranking' }],
    },
  ]
  return (
    <Sidebar
      backgroundColor='#2f323e'
      className='sidebar-container p-0'
      collapsed={collapsed}
      collapsedWidth='120px'
      width='270px'
    >
      <div className='sidebar-header'>
        <div className='h-10'>
          <Image className='img-fluid' src={LogoImage} />
        </div>
        <button className='collapse-button' onClick={toggleSidebar}>
          <FaBars />
        </button>
      </div>
      <Menu>
        {sideBarData.map((subMenuItem, subMenuIndex) => {
          return (
            <SubMenu
              defaultOpen
              className='sidebar-item'
              icon={subMenuItem?.icon || <CiCircleChevRight className='sub-menu-icon' />}
              key={`sub-menu-${subMenuIndex}`}
              label={subMenuItem.label}
            >
              {subMenuItem.menu.map((menuItem, menuIndex) => {
                return (
                  <MenuItem
                    className='sidebar-item'
                    component={<Link to={menuItem.link} />}
                    icon={<CiCircleChevRight className='menu-icon' />}
                    key={`menu-item-${menuIndex}`}
                  >
                    {menuItem.label}
                  </MenuItem>
                )
              })}
            </SubMenu>
          )
        })}
      </Menu>
      <div className='mt-3 p-3 d-flex justify-content-center'>
        <Button
          className='w-100'
          variant='danger'
          onClick={() => dispatch({ payload: { isRemoveToken: true }, type: LOGOUT })}
        >
          Đăng xuất
        </Button>
      </div>
    </Sidebar>
  )
}
