import { put, select, takeEvery } from 'redux-saga/effects'
import { userScoreAction, userScoreState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { UserScore } from '@teen-care/core'
import { apiPost } from '~core'

export const ADD_USER_SCORE = 'ADD_USER_SCORE'
export const REMOVE_USER_SCORE = 'REMOVE_USER_SCORE'
export const GET_USER_SCORES = 'GET_USER_SCORES'
export const LOAD_MORE_USER_SCORES = 'LOAD_MORE_USER_SCORES'

export const LIMIT_USER_SCORE_ITEM = 10

const userAdminRouteName = {
  route: '/user-profile-admin',
  userScoreAdd: '/add-user-scores',
  userScoreGets: '/get-user-scores',
  userScoreRemove: '/remove-user-scores',
}

interface AddUserScoreParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    score: number
    scoreDescription: string
    userId: string
  }
}
function* addUserScore({ onFailure, onSuccess, payload }: AddUserScoreParams) {
  try {
    yield put(userScoreAction.setLoading({ loading: true, userId: payload.userId }))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${userAdminRouteName.route}${userAdminRouteName.userScoreAdd}`,
    })
    const { userScores } = yield select(userScoreState)
    yield put(
      userScoreAction.setUserScores({
        userId: payload?.userId,
        userScores: [
          response?.data?.result?.userScore as UserScore,
          ...(userScores?.[payload?.userId] || []),
        ],
      }),
    )
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onFailure?.(e)
  }
}

interface GetUserScoresParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    limit?: number
    page?: number
    search?: string
    userId: string
  }
}
function* getUserScores({ onFailure, onSuccess, payload }: GetUserScoresParams) {
  try {
    yield put(userScoreAction.setLoading({ loading: true, userId: payload.userId }))
    yield put(userScoreAction.setCurrentPage({ currentPage: 1, userId: payload.userId }))
    yield put(userScoreAction.setTotalPages({ totalPages: 1, userId: payload.userId }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_SCORE_ITEM, ...payload },
      url: `${userAdminRouteName.route}${userAdminRouteName.userScoreGets}`,
    })
    yield put(
      userScoreAction.setUserScores({
        userId: payload.userId,
        userScores: response?.data?.result?.userScores as Array<UserScore>,
      }),
    )
    yield put(
      userScoreAction.setCurrentPage({
        currentPage: response?.data?.result?.currentPage || 1,
        userId: payload.userId,
      }),
    )
    yield put(
      userScoreAction.setTotalPages({
        totalPages: response?.data?.result?.totalPages || 1,
        userId: payload.userId,
      }),
    )
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onSuccess?.(response?.data?.result?.users)
  } catch (e: any) {
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onFailure?.(e)
  }
}

function* loadMoreUserScores({ onFailure, onSuccess, payload }: GetUserScoresParams) {
  try {
    const { currentPage, totalPages, userScores } = yield select(userScoreState)
    yield put(userScoreAction.setLoading({ loading: true, userId: payload.userId }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_SCORE_ITEM, ...payload },
      url: `${userAdminRouteName.route}${userAdminRouteName.userScoreGets}`,
    })
    yield put(
      userScoreAction.setUserScores({
        userId: payload?.userId,
        userScores: [
          ...(response?.data?.result?.userScores as Array<UserScore>),
          ...(userScores?.[payload?.userId] || []),
        ],
      }),
    )
    yield put(
      userScoreAction.setCurrentPage({
        currentPage: response?.data?.result?.currentPage || currentPage,
        userId: payload.userId,
      }),
    )
    yield put(
      userScoreAction.setTotalPages({
        totalPages: response?.data?.result?.totalPages || totalPages,
        userId: payload.userId,
      }),
    )

    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onSuccess?.(response?.data?.result?.users)
  } catch (e: any) {
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onFailure?.(e)
  }
}

interface RemoveUserScoreParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string; userId: string }
}
function* removeUserScore({ onFailure, onSuccess, payload }: RemoveUserScoreParams) {
  try {
    yield put(userScoreAction.setLoading({ loading: true, userId: payload.userId }))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${userAdminRouteName.route}${userAdminRouteName.userScoreRemove}`,
    })
    yield put(
      userScoreAction.removeUserScore({
        userId: payload.userId,
        userScoreId: response?.data?.result?.userScore?._id,
      }),
    )
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onSuccess?.(response?.data?.result?.userRanking)
  } catch (e: any) {
    yield put(userScoreAction.setLoading({ loading: false, userId: payload.userId }))
    onFailure?.(e)
  }
}

export function* userScoreSaga() {
  yield takeEvery(REMOVE_USER_SCORE, removeUserScore)
  yield takeEvery(ADD_USER_SCORE, addUserScore)
  yield takeEvery(GET_USER_SCORES, getUserScores)
  yield takeEvery(LOAD_MORE_USER_SCORES, loadMoreUserScores)
}
