import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '~core'
import { UserBadge } from '@teen-care/core'

export interface UserBadgeState {
  currentPage: number
  loading: boolean
  totalPages: number
  userBadges: UserBadge[]
}

const initialState: UserBadgeState = {
  currentPage: 1,
  loading: false,
  totalPages: 1,
  userBadges: [],
} as UserBadgeState

const userBadgeSlice = createSlice({
  initialState,
  name: 'userBadge',
  reducers: {
    removePostBadge: (state, action: PayloadAction<string>) => {
      state.userBadges = state.userBadges.filter((userBadge) => userBadge._id !== action.payload)
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPostBadge: (state, action: PayloadAction<UserBadge>) => {
      state.userBadges = state.userBadges.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setPostBadges: (state, action: PayloadAction<UserBadge[]>) => {
      state.userBadges = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const userBadgeAction = userBadgeSlice.actions

export const userBadgeState = (state: RootState) => state.userBadge

export const userBadgeReducer = userBadgeSlice.reducer
