import { apiPost, apiPostFormData } from '~core'
import { put, select, takeEvery } from 'redux-saga/effects'
import { userAction, userState } from '~redux'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { UserProfile } from '@teen-care/core'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_MANY_USER = 'CREATE_MANY_USER'
export const GET_USERS = 'GET_USERS'
export const LOAD_MORE_USERS = 'LOAD_MORE_USERS'
export const GET_USER = 'GET_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_USER_STATUS = 'SET_USER_STATUS'

export const LIMIT_USER_ITEM = 10

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NULL = 'NULL',
  OTHER = 'OTHER',
}

export enum DefaultUserRole {
  ADMIN = 'ADMIN',
  CHILDREN = 'CHILDREN',
  DEFAULT = 'DEFAULT',
  PARENTS = 'PARENTS',
}

const UserAdminRouteName = {
  route: '/user-profile-admin',
  userCreate: '/create-user',
  userCreates: '/create-users',
  userGet: '/get-user',
  userGets: '/get-users',
  userSetStatus: '/set-user-status',
  userUpdate: '/update-user',
}

interface UserInput {
  address?: string
  avatar?: string
  dob: number
  email?: string
  gender: UserGender
  name: string
  password: string
  phone?: string
  userRoleId: string
  username: string
}

interface CreateUserParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: UserInput
}
function* createUser({ onFailure, onSuccess, payload }: CreateUserParams) {
  try {
    const { users } = yield select(userState)
    yield put(userAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userCreate}`,
    })
    yield put(userAction.setUsers([response?.data?.result?.user as UserProfile, ...users]))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

interface CreateManyUserParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    users: Array<UserInput>
  }
}
function* createManyUser({ onFailure, onSuccess, payload }: CreateManyUserParams) {
  try {
    yield put(userAction.setLoading(true))
    const params = new FormData()
    payload.users && payload.users?.length && params.append('users', JSON.stringify(payload.users))
    yield apiPostFormData({
      params,
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userCreates}`,
    })
    yield put(userAction.setLoading(false))
    window.location.reload()
    onSuccess?.()
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}
interface UpdateUserParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    address?: string
    avatar?: string
    dob: number
    email?: string
    gender: UserGender
    name: string
    password: string
    phone?: string
    userRoleId: string
    username: string
  }
}
function* updateUser({ onFailure, onSuccess, payload }: UpdateUserParams) {
  try {
    yield put(userAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userUpdate}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

interface SetUserStatusParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string; userStatus: UserStatus }
}
function* setUserStatus({ onFailure, onSuccess, payload }: SetUserStatusParams) {
  try {
    yield put(userAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userSetStatus}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUsersParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    limit?: number
    page?: number
    search?: string
    userBadgeId?: string
    userRankingId?: string
    userRoleId?: string
    withoutReduxState?: boolean
  }
}
function* getUsers({ onFailure, onSuccess, payload }: GetUsersParams) {
  try {
    if (payload?.withoutReduxState) {
      const response: AxiosResponse = yield apiPost({
        params: { limit: LIMIT_USER_ITEM, ...payload },
        url: `${UserAdminRouteName.route}${UserAdminRouteName.userGets}`,
      })
      return onSuccess?.(response?.data?.result?.users)
    }
    yield put(userAction.setLoading(true))
    yield put(userAction.setCurrentPage(1))
    yield put(userAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_ITEM, ...payload },
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userGets}`,
    })
    yield put(userAction.setUsers(response?.data?.result?.users as UserProfile[]))
    yield put(userAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(userAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.users)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreUsers({ onFailure, onSuccess, payload }: GetUsersParams) {
  try {
    const { currentPage, totalPages } = yield select(userState)
    yield put(userAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_USER_ITEM, ...payload },
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userGets}`,
    })
    yield put(userAction.setUsers(response?.data?.result?.users as UserProfile[]))
    yield put(userAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(userAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.users)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetUserParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string }
}
function* getUser({ onFailure, onSuccess, payload }: GetUserParams) {
  try {
    yield put(userAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${UserAdminRouteName.route}${UserAdminRouteName.userGet}`,
    })
    yield put(userAction.setUser(response?.data?.result?.user as UserProfile))
    yield put(userAction.setLoading(false))
    onSuccess?.(response?.data?.result?.user)
  } catch (e: any) {
    yield put(userAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* userSaga() {
  yield takeEvery(CREATE_USER, createUser)
  yield takeEvery(CREATE_MANY_USER, createManyUser)
  yield takeEvery(GET_USER, getUser)
  yield takeEvery(GET_USERS, getUsers)
  yield takeEvery(LOAD_MORE_USERS, loadMoreUsers)
  yield takeEvery(SET_USER_STATUS, setUserStatus)
  yield takeEvery(UPDATE_USER, updateUser)
}
