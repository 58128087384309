import { Card, Form, Table } from 'react-bootstrap'
import { GET_QUESTIONS, LOAD_MORE_QUESTIONS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { Paging } from '~components'
import { QuestionItem } from './components'
import { debounce } from 'lodash'
import { questionState } from '~redux'
import { useParams } from 'react-router-dom'

export function Question() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const lessonId = params?._id || ''

  const questionsRedux = useAppSelector(questionState) || {}
  const questions = questionsRedux?.questions?.[lessonId] || []
  const currentPage = questionsRedux?.currentPage?.[lessonId]
  const totalPages = questionsRedux?.totalPages?.[lessonId]

  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)

  useEffect(() => {
    getQuestions()
  }, [lessonId])

  useEffect(() => {
    const debouncedGetQuestions = debounce(getQuestions, 1000)
    debouncedGetQuestions()
    return () => {
      debouncedGetQuestions.cancel()
    }
  }, [search])

  function getQuestions() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { lessonId, search },
      type: GET_QUESTIONS,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { lessonId, page, search }, type: LOAD_MORE_QUESTIONS })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Câu hỏi</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Label className='fw-bold'>Tìm kiếm</Form.Label>
        <Form.Control
          className='w-100'
          placeholder='Tìm kiếm'
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {['#', 'Loại', 'Độ nặng điểm', 'Câu hỏi', 'Thời gian', 'Thao tác'].map(
                  (item, index) => (
                    <th className='border-bottom' key={`${index}`}>
                      {item}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {questions.map((item, index) => (
                <QuestionItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
