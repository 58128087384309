// import i18n from 'i18n'
import numeral from 'numeral'
// import { en, vi } from '@teen-care/core'
import { vi } from './vi'

// i18n.configure({
//   defaultLocale: 'vi',
//   locales: ['vi', 'en'],
//   objectNotation: true,
//   staticCatalog: {
//     en: JSON.parse(JSON.stringify(en)),
//     vi: JSON.parse(JSON.stringify(vi)),
//   },
// })

numeral.register('locale', 'vi', {
  abbreviations: {
    billion: 'B',
    million: 'M',
    thousand: 'K',
    trillion: 'T',
  },
  currency: {
    symbol: 'đ',
  },
  delimiters: {
    decimal: ',',
    thousands: '.',
  },
  ordinal: (number: number) => {
    return number === 1 ? '' : ''
  },
})
numeral.locale('vi')

export const locales = [
  { code: 'vi-VN', language: 'Tiếng Việt', name: 'vi' },
  { code: 'en-US', language: 'English', name: 'en' },
]

export function getCurrentLanguage() {
  // return i18n.getLocale()
  return 'vi'
}

export function setCurrentLanguage(lang: string) {
  const language = locales.find((l) => {
    return l.name === lang || l.code === lang
  })
  if (language) {
    // i18n.setLocale(lang || 'vi')
    numeral.locale(language.name)
  }
}

export function __(translate: string) {
  const keys = translate.split('.')
  let result = vi as any

  for (const k of keys) {
    result = result[k]
    if (result === undefined) {
      return 'Translation not found'
    }
  }

  return result
}
