import {
  commentReducer,
  courseReducer,
  forumGroupReducer,
  forumTopicReducer,
  lessonReducer,
  postHistoryReducer,
  postReducer,
  questionReducer,
  userBadgeReducer,
  userProfileReducer,
  userRankingReducer,
  userReducer,
  userRoleReducer,
  userScoreReducer
} from '~redux'

import { combineReducers } from 'redux'

export const appReducer = combineReducers({
  comment: commentReducer,
  course: courseReducer,
  forumGroup: forumGroupReducer,
  forumTopic: forumTopicReducer,
  lesson: lessonReducer,
  post: postReducer,
  postHistory: postHistoryReducer,
  question: questionReducer,
  user: userReducer,
  userBadge: userBadgeReducer,
  userProfile: userProfileReducer,
  userRanking: userRankingReducer,
  userRole: userRoleReducer,
  userScore: userScoreReducer,
})

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET') {
    return appReducer({}, action)
  }
  return appReducer(state, action)
}
