import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_TOPIC_ITEM, REMOVE_TOPIC } from '~saga'
import { getImage, useAppDispatch, useAppSelector } from '~core'

import { CreateTopic } from './CreateTopic'
import { Topic } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userBadgeState } from '~redux'

export function TopicItem({ index, item }: { index: number; item: Topic }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(userBadgeState) || {}
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { _id, creatorId, hashTag, image, profiles, title, updatedAt } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  function removeTopic(_id: string) {
    setShow(false)
    dispatch({
      payload: { _id },
      type: REMOVE_TOPIC,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_TOPIC_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{title}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{hashTag}</span>
        </td>
        <td className='align-middle'>
          <img alt={image} src={getImage(image)} style={{ height: 90, width: 90 }} />
        </td>

        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <CreateTopic content='Sửa Topic' item={item} title='Sửa' />
          <Button className='m-1' variant='outline-danger' onClick={handleShow}>
            Xóa
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Xác nhận xóa Topic <span className='fw-bold'>{title}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant='danger' onClick={() => removeTopic(_id)}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
