import { put, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { UserProfile } from '@teen-care/core'
import { apiPost } from '~core'
import { userProfileAction } from '~redux'

const authRouteName = {
  login: '/sign-in',
  route: '/auth',
}

export const LOGIN = 'LOGIN'

interface LoginParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { password: string; username: string }
}

function* login({ onFailure, onSuccess, payload }: LoginParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: { password: payload?.password, userRoleName: 'ADMIN', username: payload?.username },
      url: `${authRouteName.route}${authRouteName.login}`,
    })
    yield put(userProfileAction.setUserProfile(response?.data?.result?.userProfile as UserProfile))
    yield put(userProfileAction.setAccessToken(response?.data?.result?.accessToken || ''))
    onSuccess?.()
  } catch (e: any) {
    onFailure?.(e)
  }
}

export function* userProfileSaga() {
  yield takeEvery(LOGIN, login)
}
