import { Card, Form, Table } from 'react-bootstrap'
import { GET_LESSONS, LOAD_MORE_LESSONS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { LessonItem } from './components'
import { Paging } from '~components'
import { debounce } from 'lodash'
import { lessonState } from '~redux'
import { useParams } from 'react-router-dom'

export function Lesson() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const courseId = params?._id || ''
  const lessonsRedux = useAppSelector(lessonState) || {}
  const lessons = lessonsRedux?.lessons?.[courseId] || []
  const currentPage = lessonsRedux?.currentPage?.[courseId]
  const totalPages = lessonsRedux?.totalPages?.[courseId]

  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)

  useEffect(() => {
    getCourses()
  }, [courseId])

  useEffect(() => {
    const debouncedGetCourses = debounce(getCourses, 1000)
    debouncedGetCourses()
    return () => {
      debouncedGetCourses.cancel()
    }
  }, [search])

  function getCourses() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { courseId, search },
      type: GET_LESSONS,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { courseId, page, search }, type: LOAD_MORE_LESSONS })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Bài học</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Label className='fw-bold'>Tìm kiếm</Form.Label>
        <Form.Control
          className='w-100'
          placeholder='Tìm kiếm'
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {['#', 'Tên', 'Mô tả', 'Mô tả 2', 'Điểm', 'Thời gian', 'Thao tác'].map(
                  (item, index) => (
                    <th className='border-bottom' key={`${index}`}>
                      {item}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {lessons.map((item, index) => (
                <LessonItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
