import { Comment, InfoProfile } from '@teen-care/core'

import { Avatar } from '~components'
import { __ } from '~localization'
import { getUserProfile } from '~utils'

interface CommentItemProps extends Comment {
  limitCharacter?: number
  profiles: Array<InfoProfile>
}

export function CommentItem({
  content,
  creatorId,
  isPrivate,
  limitCharacter = 0,
  profiles,
}: CommentItemProps) {
  const creator = getUserProfile({ profiles, userId: creatorId })

  return (
    <div className='row d-flex align-items-start border-bottom mb-2 pb-2'>
      <Avatar small avatarUrl={creator.avatar} className='mt-1' />
      <div className='col'>
        <div className='fw-bold'>
          {creator?.name} {isPrivate ? '(Ẩn danh)' : ''}
        </div>
        <div className='h6'>
          {content}
          {limitCharacter > 0 && content?.length > 300 ? (
            <span className='read-more'>{' ' + __('forum.readMore')}</span>
          ) : null}
        </div>
      </div>
    </div>
  )
}
