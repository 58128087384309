import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Post } from '@teen-care/core'
import type { RootState } from '~core'

export interface PostState {
  currentPage: number
  loading: boolean
  posts: Post[]
  totalPages: number
}

const initialState: PostState = {
  currentPage: 1,
  loading: false,
  posts: [],
  totalPages: 1,
} as PostState

const postSlice = createSlice({
  initialState,
  name: 'post',
  reducers: {
    removePost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post._id !== action.payload)
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPost: (state, action: PayloadAction<Post>) => {
      state.posts = state.posts.map((item) => {
        if (item?._id === action?.payload?._id) {
          return {
            ...item,
            ...action?.payload,
          }
        }
        return item
      })
    },
    setPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
  },
})

export const postAction = postSlice.actions

export const postState = (state: RootState) => state.post

export const postReducer = postSlice.reducer
