import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_USER_RANKING_ITEM, REMOVE_USER_RANKING } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { CreateUserRanking } from './CreateUserRanking'
import { UserRanking } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userRankingState } from '~redux'

export function UserRankingItem({ index, item }: { index: number; item: UserRanking }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(userRankingState) || {}
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { _id, creatorId, profiles, rankName, score, updatedAt } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  function removeUserRanking(_id: string) {
    setShow(false)
    dispatch({
      payload: { _id },
      type: REMOVE_USER_RANKING,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>
            {(currentPage - 1) * LIMIT_USER_RANKING_ITEM + index + 1}
          </span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{rankName}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{score}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <CreateUserRanking content='Sửa Cấp độ' item={item} title='Sửa' />
          <Button className='m-1' variant='outline-danger' onClick={handleShow}>
            Xóa
          </Button>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa Cấp độ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Xác nhận xóa Cấp độ <span className='fw-bold'>{rankName}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant='danger' onClick={() => removeUserRanking(_id)}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
