import { Post, UserProfile } from '@teen-care/core'
import { faHeart, faMessage } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from '~core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LOVE_POST } from '~saga'
import { __ } from '~localization'
import { userProfileState } from '~redux'

export function ReactionPost(props: { item: Post }) {
  const { _id, comments, reactionLove } = props.item || {}
  const dispatch = useAppDispatch()
  const userProfile = (useAppSelector(userProfileState).userProfile || {}) as UserProfile

  const isLoved = reactionLove?.findIndex((e) => e === userProfile?._id) !== -1

  function lovePost() {
    dispatch({
      payload: { postId: _id },
      type: LOVE_POST,
    })
  }

  return (
    <div className='mb-3'>
      <div className='d-flex justify-content-between'>
        <span>
          <FontAwesomeIcon color='#E53E3E' icon={faHeart} /> {reactionLove?.length || 0}
        </span>
        <span>{`${comments?.length || 0} ${__('forum.comments').toLowerCase()}`}</span>
      </div>
      <div className='d-flex justify-content-between'>
        <span onClick={lovePost}>
          <FontAwesomeIcon color={isLoved ? '#E53E3E' : '#A0A0A0'} icon={faHeart} />{' '}
          {__('forum.like')}
        </span>

        <span className='action-label'>
          <FontAwesomeIcon className='icon-message' icon={faMessage} /> {__('forum.comments')}
        </span>
      </div>
    </div>
  )
}
