// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-item {
  background-color: #2f323e;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: #96989e;
}

.sidebar-container {
  height: 100vh;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2f323e;
}

.collapse-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.collapsed .sidebar-header {
  justify-content: center;
}

.sub-menu-icon {
  width: 22px;
  height: 22px;
}

.menu-icon {
  width: 18px;
  height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".sidebar-item {\n  background-color: #2f323e;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 400;\n  color: #96989e;\n}\n\n.sidebar-container {\n  height: 100vh;\n}\n\n.sidebar-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  background-color: #2f323e;\n}\n\n.collapse-button {\n  background: none;\n  border: none;\n  color: #fff;\n  font-size: 1.5rem;\n  cursor: pointer;\n}\n\n.collapsed .sidebar-header {\n  justify-content: center;\n}\n\n.sub-menu-icon {\n  width: 22px;\n  height: 22px;\n}\n\n.menu-icon {\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
