import { InfoProfile } from '@teen-care/core'

export function getUserProfile({
  profiles = [],
  userId,
}: {
  profiles: Array<InfoProfile>
  userId: string
}) {
  const userProfile = [...(profiles || [])].reverse().find((e) => e._id === userId) || {
    avatar: '',
    gender: 'NULL',
    name: '',
    userBadgeColor: '',
    userBadgeIcon: '',
    userBadgeName: '',
    userBadgeNameColor: '',
  }
  return userProfile
}
