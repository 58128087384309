import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Question } from '@teen-care/core'
import type { RootState } from '~core'

export interface QuestionState {
  currentPage: Record<string, number>,
  loading: Record<string, boolean>,
  questions: Record<string, Question[]>,
  totalPages: Record<string, number>
}

const initialState: QuestionState = {
  currentPage: {},
  loading: {},
  questions: {},
  totalPages: {},
} as QuestionState

const questionSlice = createSlice({
  initialState,
  name: 'question',
  reducers: {
    removeQuestion: (state, action: PayloadAction<{ lessonId: string; questionId: string }>) => {
      state.questions = {
        ...state.questions,
        [action.payload.lessonId]: state.questions[action.payload.lessonId].filter((question) => question._id !== action.payload.questionId),
      }
    },
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number, lessonId: string; }>) => {
      state.currentPage = {
        ...state.currentPage,
        [action.payload.lessonId]: action.payload.currentPage,
      }
    },
    setLoading: (state, action: PayloadAction<{ lessonId: string; loading: boolean }>) => {
      state.loading = {
        ...state.loading,
        [action.payload.lessonId]: action.payload.loading,
      }
    },
    setQuestion: (state, action: PayloadAction<{ lessonId: string; question: Question }>) => {
      state.questions = {
        ...state.questions,
        [action.payload.lessonId]: state.questions[action.payload.lessonId].map((item) => {
          if (item?._id === action?.payload?.question?._id) {
            return {
              ...item,
              ...action?.payload?.question,
            }
          }
          return item
        }),
      }
    },
    setQuestions: (state, action: PayloadAction<{ lessonId: string; questions: Question[] }>) => {
      state.questions = {
        ...state.questions,
        [action.payload.lessonId]: action.payload.questions,
      }
    },
    setTotalPages: (state, action: PayloadAction<{ lessonId: string; totalPages: number }>) => {
      state.totalPages = {
        ...state.totalPages,
        [action.payload.lessonId]: action.payload.totalPages,
      }
    },
  },
})

export const questionAction = questionSlice.actions

export const questionState = (state: RootState) => state.question

export const questionReducer = questionSlice.reducer
