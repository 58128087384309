import { Button, Form, Modal } from 'react-bootstrap'

import { ADD_USER_SCORE } from '~saga'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function CreateUserScore({
  content,
  textOnly,
  title,
  userId,
}: {
  content: string
  textOnly?: boolean
  title: string
  userId: string
}) {
  const dispatch = useAppDispatch()
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [score, setScore] = useState<string>('')
  const [scoreDescription, setScoreDescription] = useState<string>('')

  const clearData = () => {
    setShowCreate(false)
    setScore('')
    setScoreDescription('')
  }

  const handleShow = () => {
    setShowCreate(true)
  }

  function addUserScore() {
    dispatch({
      onSuccess: clearData,
      payload: { score: parseFloat(score), scoreDescription, userId },
      type: ADD_USER_SCORE,
    })
  }

  return (
    <>
      {textOnly ? (
        <div onClick={handleShow}>{title}</div>
      ) : (
        <Button className='m-1' variant='dark' onClick={handleShow}>
          {title}
        </Button>
      )}

      <Modal fullscreen show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Số điểm</Form.Label>
            <Form.Control
              placeholder='Nhập điểm'
              value={score}
              onChange={(e) => setScore(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Ghi chú</Form.Label>
            <Form.Control
              placeholder='Nhập ghi chú'
              value={scoreDescription}
              onChange={(e) => setScoreDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={addUserScore}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
