import { PostHistory } from '@teen-care/core'
import { Table } from 'react-bootstrap'
import dayjs from 'dayjs'

export function PostHistoryComponent({ postHistory = [] }: { postHistory?: Array<PostHistory> }) {
  return (
    <div style={{ overflowX: 'auto' }}>
      {postHistory.map((item, index) => {
        return item.postStatus === 'PENDING' && item?.detail?.length ? (
          <div key={index}>
            <div className='d-flex align-items-center mb-2' key={index}>
              <div className='m-1'>{dayjs(item.createdAt).format('HH:mm DD/MM/YYYY')}: </div>
              <div className='m-1 fw-bold'>{item.userName}</div>
              <div className='m-1'>đã yêu cầu cập nhật bài viết</div>
            </div>
            <Table bordered hover striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Trường update</th>
                  <th>Hiện tại</th>
                  <th>Cập nhật</th>
                </tr>
              </thead>
              <tbody>
                {item?.detail?.map((item, indexItem) => {
                  return (
                    <tr key={indexItem}>
                      <td>{indexItem + 1}</td>
                      <div className='col'>{item.key}</div>
                      <td>{JSON.stringify(item.current)}</td>
                      <td>{JSON.stringify(item.update)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className='d-flex align-items-center mb-2' key={index}>
            <div className='m-1'>{dayjs(item.createdAt).format('HH:mm DD/MM/YYYY')}: </div>
            <div className='m-1 fw-bold'>{item.userName}</div>
            {item.postStatus === 'APPROVE' ? <div className='m-1'>đã duyệt bài viết</div> : null}
            {item.postStatus === 'DELETE' ? <div className='m-1'>đã xóa bài viết</div> : null}
            {item.postStatus === 'REJECT' ? (
              <div className='m-1'>đã từ chối bài viết với lý do: {item?.reasonReject || ''}</div>
            ) : null}
            {item.postStatus === 'PENDING' && !item?.detail ? (
              <div className='m-1'>đã yêu cầu đăng bài viết</div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}
