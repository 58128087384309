import { Comment, Post } from '@teen-care/core'
import { commentAction, commentState, postAction } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'
import { unionBy } from 'lodash'

export const CREATE_COMMENT = 'CREATE_COMMENT'
export const GET_COMMENTS = 'GET_COMMENTS'
export const LOAD_MORE_COMMENTS = 'LOAD_MORE_COMMENTS'

const LIMIT_ITEM = 0

const ForumRouteName = {
  commentCreate: '/create-comment',
  commentGets: '/get-comments',
  lovePost: '/love',
  route: '/forum',
  votePost: '/vote',
}

interface CreateCommentParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { content: string; isPrivate: boolean; postId: string }
}
function* createComment({ onFailure, onSuccess, payload }: CreateCommentParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${ForumRouteName.route}${ForumRouteName.commentCreate}`,
    })
    const { comments } = yield select(commentState)
    const currentComments = comments?.[payload.postId] || []
    yield put(
      commentAction.setComments({
        comments: unionBy([response?.data?.result?.comment as Comment, ...currentComments], '_id'),
        postId: payload.postId,
      }),
    )
    yield put(postAction.setPost(response?.data?.result?.post as Post))
    onSuccess?.(response?.data?.result?.comment)
  } catch (e: any) {
    onFailure?.(e)
  }
}

interface GetCommentsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { lastId?: string; limit?: number; postId: string; search?: string }
}
function* getComments({ onFailure, onSuccess, payload }: GetCommentsParams) {
  try {
    yield put(commentAction.setLoading({ loading: true, postId: payload.postId }))
    yield put(commentAction.setLoadMore({ loadMore: true, postId: payload.postId }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_ITEM, ...payload },
      url: `${ForumRouteName.route}${ForumRouteName.commentGets}`,
    })
    yield put(
      commentAction.setComments({
        comments: response?.data?.result?.comments as Comment[],
        postId: payload.postId,
      }),
    )
    yield put(
      commentAction.setLoadMore({
        loadMore: response?.data?.result?.comments?.length === LIMIT_ITEM,
        postId: payload.postId,
      }),
    )
    yield put(commentAction.setLoading({ loading: false, postId: payload.postId }))
    onSuccess?.(response?.data?.result?.comments)
  } catch (e: any) {
    onFailure?.(e)
  }
}

export function* commentSaga() {
  yield takeEvery(CREATE_COMMENT, createComment)
  yield takeEvery(GET_COMMENTS, getComments)
}
