import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PostHistory } from '@teen-care/core'
import type { RootState } from '~core'

export interface PostHistoryState {
  loadMore: Record<string, boolean>
  loading: Record<string, boolean>
  postHistory: Record<string, PostHistory[]>
}

const initialState: PostHistoryState = {
  loadMore: {},
  loading: {},
  postHistory: {},
} as PostHistoryState

const postHistorySlice = createSlice({
  initialState,
  name: 'postHistory',
  reducers: {
    setLoadMore: (state, action: PayloadAction<{ loadMore: boolean; postId: string }>) => {
      state.loadMore = {
        ...state.loadMore,
        [action.payload.postId]: action.payload.loadMore,
      }
    },
    setLoading: (state, action: PayloadAction<{ loading: boolean; postId: string }>) => {
      state.loading = {
        ...state.loading,
        [action.payload.postId]: action.payload.loading,
      }
    },
    setPostHistory: (
      state,
      action: PayloadAction<{ postHistory: PostHistory[]; postId: string }>,
    ) => {
      state.postHistory = {
        ...state.postHistory,
        [action.payload.postId]: action.payload.postHistory,
      }
    },
  },
})

export const postHistoryAction = postHistorySlice.actions

export const postHistoryState = (state: RootState) => state.postHistory

export const postHistoryReducer = postHistorySlice.reducer
