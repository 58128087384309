import { Button, Form, Modal, Table } from 'react-bootstrap'
import {
  CREATE_FORUM_GROUP,
  GET_FORUM_TOPICS,
  GET_USERS,
  GET_USER_ROLES,
  UPDATE_FORUM_GROUP,
} from '~saga'
import { ForumGroup, UserProfile, UserRole } from '@teen-care/core'
import { debounce, unionBy } from 'lodash'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { UserItem } from './UserItem'
import { userRoleState } from '~redux'

export function CreateGroup({
  content,
  item,
  title,
}: {
  content: string
  item?: ForumGroup
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const { userRoles } = useAppSelector(userRoleState) || {}
  const [users, setUsers] = useState<UserProfile[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [groupName, setGroupName] = useState<string>(item?.groupName || '')
  const [introduction, setIntroduction] = useState<string>(item?.introduction || '')
  const [adminIds, setAdminIds] = useState<string[]>(item?.adminIds || [])
  const [userIds, setUserIds] = useState<string[]>(item?.userIds || [])

  const selectedRoleIds =
    userRoles
      ?.filter(
        (e) =>
          e.forumGroupIds?.length &&
          e.forumGroupIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1,
      )
      ?.map((e) => e._id) || []
  const [roleIds, setRoleIds] = useState<string[]>([])

  useEffect(() => {
    const debouncedGetPosts = debounce(
      () =>
        search?.length
          ? dispatch({
              onSuccess: (data: UserProfile[]) => setUsers(data),
              payload: { limit: 0, search, withoutReduxState: true },
              type: GET_USERS,
            })
          : setUsers([]),
      1000,
    )
    debouncedGetPosts()
    return () => {
      debouncedGetPosts.cancel()
    }
  }, [search])

  useEffect(() => {
    dispatch({ type: GET_USER_ROLES })
    dispatch({ type: GET_FORUM_TOPICS })
  }, [])

  const clearData = () => {
    setShow(false)
    setGroupName('')
    setIntroduction('')
    setAdminIds([])
    setRoleIds([])
    setUserIds([])
    setUsers([])
  }

  const handleShow = () => {
    setShow(true)
    setGroupName(isUpdate ? item?.groupName || '' : '')
    setIntroduction(isUpdate ? item?.introduction || '' : '')
    setAdminIds(isUpdate ? item?.adminIds || [] : [])
    setUserIds(isUpdate ? item?.userIds || [] : [])
    setRoleIds(isUpdate ? selectedRoleIds || [] : [])
  }

  function createGroup() {
    dispatch({
      onSuccess: () => {
        clearData()
        dispatch({ type: GET_USER_ROLES })
      },
      payload: {
        ...(isUpdate ? { _id: item?._id } : {}),
        adminIds,
        groupName,
        introduction,
        roleIds,
        userIds,
      },
      type: isUpdate ? UPDATE_FORUM_GROUP : CREATE_FORUM_GROUP,
    })
  }

  return (
    <>
      <Button className='m-1' variant='dark' onClick={handleShow}>
        {title}
      </Button>

      <Modal fullscreen show={show} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Tên nhóm</Form.Label>
            <Form.Control
              placeholder='Nhập tên nhóm'
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Mô tả</Form.Label>
            <Form.Control
              placeholder='Nhập mô tả'
              value={introduction}
              onChange={(e) => setIntroduction(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Thành viên truy cập</Form.Label>
            <div className='mb-1'>
              {
                'Danh sách vai trò (Người dùng có vai trò thuộc danh sách sẽ tự động được truy cập nhóm)'
              }
            </div>
            {(userRoles || []).map((item: UserRole) => {
              const isChecked =
                roleIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
              return (
                <Form.Check
                  checked={isChecked}
                  className='mb-1'
                  key={item?._id}
                  label={item?.userRoleName}
                  onChange={(e: any) => {
                    setRoleIds((current) =>
                      e?.target?.checked
                        ? [item?._id, ...current]
                        : current?.filter((e) => e !== item?._id),
                    )
                  }}
                />
              )
            })}

            <div className='mb-1 mt-3'>{'Danh sách người dùng'}</div>
            <Form.Control
              placeholder='Tìm họ tên, SĐT, email'
              onChange={(e) => setSearch(e.target.value)}
            />

            <Table hover className='user-table align-items-center'>
              <thead>
                <tr>
                  {[
                    'Username',
                    'Tên',
                    'SĐT',
                    'Vai trò',
                    'Huy hiệu',
                    'Điểm tích lũy',
                    'Cấp độ',
                    'Trạng thái',
                    'Thao tác',
                  ].map((item, index) => (
                    <th className='border-bottom' key={`${index}`}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {unionBy([...(item?.userProfiles || []), ...users], '_id').map((item, index) => (
                  <UserItem
                    adminIds={adminIds}
                    index={index}
                    item={item}
                    key={`${index}`}
                    setAdminIds={setAdminIds}
                    setUserIds={setUserIds}
                    userIds={userIds}
                  />
                ))}
              </tbody>
            </Table>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createGroup}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
