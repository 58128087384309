import { Card, Dropdown, Form, InputGroup, Table } from 'react-bootstrap'
import { CreateUser, UserItem } from './components'
import { GET_USERS, GET_USER_ROLES, LOAD_MORE_USERS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useRef, useState } from 'react'
import { userBadgeState, userRankingState, userRoleState } from '~redux'

import { ImportListUser } from './components/ImportListUser'
import { Paging } from '~components'
import { debounce } from 'lodash'
import { userState } from '~redux'

export function User() {
  const dispatch = useAppDispatch()
  const { currentPage, totalPages, users } = useAppSelector(userState) || {}
  const { userRoles } = useAppSelector(userRoleState) || {}
  const { userBadges } = useAppSelector(userBadgeState) || {}
  const { userRankings } = useAppSelector(userRankingState) || {}
  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)
  const [userRoleId, setUserRoleId] = useState<string>('')
  const [userBadgeId, setUserBadgeId] = useState<string>('')
  const [userRankingId, setUserRankingId] = useState<string>('')
  const userRoleSelected = userRoles?.find((e) => e?._id?.toString() === userRoleId)
  const userBadgeSelected = userBadges?.find((e) => e?._id?.toString() === userBadgeId)
  const userRankingSelected = userRankings?.find((e) => e?._id?.toString() === userRankingId)
  const createUserRefs = useRef<any>()

  useEffect(() => {
    getUsers()
    dispatch({ type: GET_USER_ROLES })
  }, [])

  useEffect(() => {
    const debouncedGetPosts = debounce(getUsers, 1000)
    debouncedGetPosts()
    return () => {
      debouncedGetPosts.cancel()
    }
  }, [search, userRoleId, userBadgeId, userRankingId])

  function getUsers() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { search, userBadgeId, userRankingId, userRoleId },
      type: GET_USERS,
    })
  }

  function onChangePage(page: number) {
    isFetched &&
      dispatch({
        payload: { page, search, userBadgeId, userRankingId, userRoleId },
        type: LOAD_MORE_USERS,
      })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Group className='d-flex align-items-center justify-content-between mb-3'>
        <Form.Label className='fw-bold'>Danh sách người dùng</Form.Label>
        <Dropdown>
          <Dropdown.Toggle id='dropdown-basic' variant='dark'>
            Thêm người dùng
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as='div'>
              <CreateUser
                textOnly
                buttonLbl='Thêm'
                content='Thêm người dùng'
                title='Thêm người dùng'
              />
            </Dropdown.Item>
            <Dropdown.Item as='div'>
              <ImportListUser textOnly content='Import danh sách' title='Import danh sách' />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <InputGroup className='mb-3'>
        <InputGroup.Text className='fw-bold'>Người dùng</InputGroup.Text>
        <Form.Control
          placeholder='Tìm họ tên, SĐT, email'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>
      <InputGroup className='mb-3'>
        <InputGroup.Text className='fw-bold'>Vai trò</InputGroup.Text>
        <Form.Select
          onChange={(e: any) => {
            setUserRoleId(e.target.value === 'default' ? '' : e.target.value)
          }}
        >
          <option value='default'>
            {userRoleSelected?._id ? userRoleSelected?.userRoleName : 'Chọn vai trò'}
          </option>
          {userRoles?.map((item, index) => {
            return (
              <option key={index} value={item?._id}>
                {item?.userRoleName}
              </option>
            )
          })}
        </Form.Select>
        <InputGroup.Text className='fw-bold'>Huy hiệu</InputGroup.Text>
        <Form.Select
          onChange={(e: any) => {
            setUserBadgeId(e.target.value === 'default' ? '' : e.target.value)
          }}
        >
          <option value='default'>
            {userBadgeSelected?._id ? userBadgeSelected?.userBadgeName : 'Chọn huy hiệu'}
          </option>
          {userBadges?.map((item, index) => {
            return (
              <option key={index} value={item?._id}>
                {item?.userBadgeName}
              </option>
            )
          })}
        </Form.Select>
        <InputGroup.Text className='fw-bold'>Cấp độ</InputGroup.Text>
        <Form.Select
          onChange={(e: any) => {
            setUserRankingId(e.target.value === 'default' ? '' : e.target.value)
          }}
        >
          <option value='default'>
            {userRankingSelected?._id ? userRankingSelected?.rankName : 'Chọn huy hiệu'}
          </option>
          {userRankings?.map((item, index) => {
            return (
              <option key={index} value={item?._id}>
                {item?.rankName}
              </option>
            )
          })}
        </Form.Select>
      </InputGroup>

      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {[
                  '#',
                  'Username',
                  'Tên',
                  'SĐT',
                  'Vai trò',
                  'Huy hiệu',
                  'Điểm tích lũy',
                  'Cấp độ',
                  'Trạng thái',
                  'Thao tác',
                ].map((item, index) => (
                  <th className='border-bottom' key={`${index}`}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((item, index) => (
                <UserItem
                  editItem={createUserRefs?.current?.editItem}
                  index={index}
                  item={item}
                  key={`${index}`}
                />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
      <CreateUser
        textOnly
        buttonLbl='Sửa'
        content='Thêm người dùng'
        ref={createUserRefs}
        title=''
      />
    </div>
  )
}
