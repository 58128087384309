import { courseAction, userRoleState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { Course } from '@teen-care/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'

export const GET_COURSES = 'GET_COURSES'
export const LOAD_MORE_COURSES = 'LOAD_MORE_COURSES'

export const LIMIT_COURSE_ITEM = 0

const LearnRouteName = {
  courseGets: '/get-courses',
  route: '/learn-admin',
}

interface GetCoursesParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; search?: string }
}
function* getCourses({ onFailure, onSuccess, payload }: GetCoursesParams) {
  try {
    yield put(courseAction.setLoading(true))
    yield put(courseAction.setCurrentPage(1))
    yield put(courseAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_COURSE_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.courseGets}`,
    })
    yield put(courseAction.setCourses(response?.data?.result?.courses as Course[]))
    yield put(courseAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(courseAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.courses)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMoreCourses({ onFailure, onSuccess, payload }: GetCoursesParams) {
  try {
    const { currentPage, totalPages } = yield select(userRoleState)
    yield put(courseAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_COURSE_ITEM, ...payload },
      url: `${LearnRouteName.route}${LearnRouteName.courseGets}`,
    })
    yield put(courseAction.setCourses(response?.data?.result?.courses as Course[]))
    yield put(courseAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(courseAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(courseAction.setLoading(false))
    onSuccess?.(response?.data?.result?.courses)
  } catch (e: any) {
    yield put(courseAction.setLoading(false))
    onFailure?.(e)
  }
}

export function* courseSaga() {
  yield takeEvery(GET_COURSES, getCourses)
  yield takeEvery(LOAD_MORE_COURSES, loadMoreCourses)
}
