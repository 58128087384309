import { Card, Form, Table } from 'react-bootstrap'
import { CreateUserScore, UserScoreItem } from './components'
import { GET_USER_SCORES, LOAD_MORE_USER_SCORES } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'
import { userScoreState, userState } from '~redux'

import { Paging } from '~components'
import { UserProfile } from '@teen-care/core'
import { useParams } from 'react-router-dom'

export function UserScoreHistory() {
  const params = useParams()
  const userId = params?._id || ''
  const dispatch = useAppDispatch()
  const { users } = useAppSelector(userState) || {}
  const userScoreRedux = useAppSelector(userScoreState) || {}
  const [isFetched, setFetched] = useState<boolean>(false)
  const user = (users?.find((e) => e._id === userId) || {}) as UserProfile

  const currentPage = userScoreRedux?.currentPage?.[userId] || 1
  const totalPages = userScoreRedux?.totalPages?.[userId] || 1
  const userScores = userScoreRedux?.userScores?.[userId] || []

  useEffect(() => {
    getUserScores()
  }, [])

  function getUserScores() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { userId: user?._id },
      type: GET_USER_SCORES,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { page, userId: user?._id }, type: LOAD_MORE_USER_SCORES })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>{`Lịch sử tích điểm của [${user?.name}] ${user?.username}`}</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <div />
        <CreateUserScore content='Thêm điểm' title='Thêm điểm' userId={userId} />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {[
                  '#',
                  'Người dùng',
                  'Họ và tên',
                  'Điểm',
                  'Ghi chú',
                  'Thời gian',
                  'Người tạo',
                  'Thao tác',
                ].map((item, index) => (
                  <th className='border-bottom' key={`${index}`}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userScores?.map((item, index) => (
                <UserScoreItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
