import { Post, PostHistory } from '@teen-care/core'
import { postAction, postHistoryAction, postHistoryState, postState } from '~redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'
import { unionBy } from 'lodash'

export const GET_POSTS = 'GET_POSTS'
export const LOAD_MORE_POSTS = 'LOAD_MORE_POSTS'
export const GET_POST = 'GET_POST'
export const LOVE_POST = 'LOVE_POST'
export const VOTE_POST = 'VOTE_POST'
export const MANAGE_POST = 'MANAGE_POST'

export const LIMIT_POST_ITEM = 10

const forumAdminRouteName = {
  managePost: '/manage-post',
  postGet: '/get-post',
  postGets: '/get-posts',
  route: '/forum-admin',
}

const ForumRouteName = {
  lovePost: '/love',
  route: '/forum',
  votePost: '/vote',
}

interface GetPostsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { limit?: number; page?: number; postStatus?: string; search?: string }
}
function* getPosts({ onFailure, onSuccess, payload }: GetPostsParams) {
  try {
    yield put(postAction.setLoading(true))
    yield put(postAction.setCurrentPage(1))
    yield put(postAction.setTotalPages(1))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_POST_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.postGets}`,
    })
    yield put(postAction.setPosts(response?.data?.result?.posts as Post[]))
    yield put(postAction.setCurrentPage(response?.data?.result?.currentPage || 1))
    yield put(postAction.setTotalPages(response?.data?.result?.totalPages || 1))
    yield put(postAction.setLoading(false))
    onSuccess?.(response?.data?.result?.posts)
  } catch (e: any) {
    yield put(postAction.setLoading(false))
    onFailure?.(e)
  }
}

function* loadMorePosts({ onFailure, onSuccess, payload }: GetPostsParams) {
  try {
    const { currentPage, totalPages } = yield select(postState)
    yield put(postAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_POST_ITEM, ...payload },
      url: `${forumAdminRouteName.route}${forumAdminRouteName.postGets}`,
    })
    yield put(postAction.setPosts(response?.data?.result?.posts as Post[]))
    yield put(postAction.setCurrentPage(response?.data?.result?.currentPage || currentPage))
    yield put(postAction.setTotalPages(response?.data?.result?.totalPages || totalPages))
    yield put(postAction.setLoading(false))
    onSuccess?.(response?.data?.result?.posts)
  } catch (e: any) {
    yield put(postAction.setLoading(false))
    onFailure?.(e)
  }
}

interface GetPostParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { _id: string; search?: string }
}
function* getPost({ onFailure, onSuccess, payload }: GetPostParams) {
  try {
    yield put(postAction.setLoading(true))
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.postGet}`,
    })
    const { posts } = yield select(postState)
    yield put(postAction.setPosts(unionBy([...posts, response?.data?.result?.post as Post], '_id')))
    yield put(postAction.setLoading(false))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    yield put(postAction.setLoading(false))
    onFailure?.(e)
  }
}

interface LovePostParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { postId: string }
}
function* lovePost({ onFailure, onSuccess, payload }: LovePostParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${ForumRouteName.route}${ForumRouteName.lovePost}`,
    })
    yield put(postAction.setPost(response?.data?.result?.post as Post))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    onFailure?.(e)
  }
}

interface VotePostParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { pollId: string; postId: string }
}
function* votePost({ onFailure, onSuccess, payload }: VotePostParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${ForumRouteName.route}${ForumRouteName.votePost}`,
    })
    yield put(postAction.setPost(response?.data?.result?.post as Post))
    onSuccess?.(response?.data?.result?.post)
  } catch (e: any) {
    onFailure?.(e)
  }
}

interface managePostParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    _id: string
    forumTopicIds: string[]
    postStatus: string
    reasonReject?: string
  }
}
function* managePost({ onFailure, onSuccess, payload }: managePostParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${forumAdminRouteName.route}${forumAdminRouteName.managePost}`,
    })
    yield put(postAction.setPost(response?.data?.result?.post as Post))
    const { postHistory } = yield select(postHistoryState)
    const currentPostHistory = postHistory?.[payload._id] || []
    yield put(
      postHistoryAction.setPostHistory({
        postHistory: unionBy(
          [response?.data?.result?.postHistory as PostHistory, ...currentPostHistory],
          '_id',
        ),
        postId: payload._id,
      }),
    )
    onSuccess?.(response)
  } catch (e: any) {
    onFailure?.(e)
  }
}

export function* postSaga() {
  yield takeEvery(GET_POSTS, getPosts)
  yield takeEvery(LOAD_MORE_POSTS, loadMorePosts)
  yield takeEvery(GET_POST, getPost)
  yield takeEvery(LOVE_POST, lovePost)
  yield takeEvery(VOTE_POST, votePost)
  yield takeEvery(MANAGE_POST, managePost)
}
