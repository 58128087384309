import { put, takeEvery } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { PostHistory } from '@teen-care/core'
import { apiPost } from '~core'
import { postHistoryAction } from '~redux'

export const GET_POST_HISTORY = 'GET_POST_HISTORY'

const LIMIT_ITEM = 0

const ForumRouteName = {
  postHistoryGet: '/get-post-history',
  route: '/forum-admin',
}

interface GetHistoryHistoryParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: { lastId?: string; limit?: number; postId: string; search?: string }
}
function* getPostHistory({ onFailure, onSuccess, payload }: GetHistoryHistoryParams) {
  try {
    yield put(postHistoryAction.setLoading({ loading: true, postId: payload.postId }))
    yield put(postHistoryAction.setLoadMore({ loadMore: true, postId: payload.postId }))
    const response: AxiosResponse = yield apiPost({
      params: { limit: LIMIT_ITEM, ...payload },
      url: `${ForumRouteName.route}${ForumRouteName.postHistoryGet}`,
    })
    yield put(
      postHistoryAction.setPostHistory({
        postHistory: response?.data?.result?.postHistory as PostHistory[],
        postId: payload.postId,
      }),
    )
    yield put(
      postHistoryAction.setLoadMore({
        loadMore: response?.data?.result?.comments?.length === LIMIT_ITEM,
        postId: payload.postId,
      }),
    )
    yield put(postHistoryAction.setLoading({ loading: false, postId: payload.postId }))
    onSuccess?.(response?.data?.result?.comments)
  } catch (e: any) {
    onFailure?.(e)
  }
}

export function* postHistorySaga() {
  yield takeEvery(GET_POST_HISTORY, getPostHistory)
}
