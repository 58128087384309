import '~assets/style/Post.css'

import { Button, Modal } from 'react-bootstrap'
import { LIMIT_USER_ROLE_ITEM, REMOVE_USER_ROLE } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { CreateUserRole } from './CreateUserRole'
import { UserRole } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useState } from 'react'
import { userState } from '~redux'

export function UserRoleItem({ index, item }: { index: number; item: UserRole }) {
  const { currentPage } = useAppSelector(userState) || {}
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false)

  const { _id, creatorId, defaultUserRole, profiles, updatedAt, userRoleName } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  function removeUserRole() {
    setShow(false)
    dispatch({
      payload: { _id },
      type: REMOVE_USER_ROLE,
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_USER_ROLE_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{userRoleName}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>
            {updatedAt ? dayjs(updatedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{creator?.name}</span>
        </td>
        <td className='align-middle'>
          <Button className='m-1' variant='outline-primary'>
            <CreateUserRole textOnly content='Cập nhật vai trò' item={item} title='Sửa' />
          </Button>

          {!defaultUserRole ? (
            <Button className='m-1' variant='outline-danger' onClick={handleShow}>
              Xóa
            </Button>
          ) : null}
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận xóa vai trò</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Bạn có chắc chắn muốn xoá vai trò <span className='fw-bold'>{userRoleName}</span> ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button variant={'danger'} onClick={removeUserRole}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
