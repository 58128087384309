import '~assets/style/Post.css'

import { DELETE_QUESTION, LIMIT_POST_ITEM } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'

import { Button } from 'react-bootstrap'
import { Question } from '@teen-care/core'
import { courseState } from '~redux'
import dayjs from 'dayjs'

export function QuestionItem({ index, item }: { index: number; item: Question }) {
  const dispatch = useAppDispatch()
  const { currentPage } = useAppSelector(courseState) || {}
  const { createdAt, question, questionType, weightScore } = item || {}

  function deleteItem(e: React.MouseEvent) {
    e.stopPropagation()
    dispatch({
      payload: { lessonId: item.lessonId, questionId: item._id },
      type: DELETE_QUESTION,
    })
  }

  return (
    <tr>
      <td className='align-middle'>
        <span className='fw-normal'>{(currentPage - 1) * LIMIT_POST_ITEM + index + 1}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{questionType}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{weightScore}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{question}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>
          {createdAt ? dayjs(createdAt).format('HH:mm DD/MM/YYYY') : '...'}
        </span>
      </td>
      <td className='align-middle'>
        <Button variant='danger' onClick={deleteItem}>
          Xóa
        </Button>
      </td>
    </tr>
  )
}
