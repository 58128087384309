export const vi = {
  auth: {
    account: 'Tài khoản/Số điện thoại',
    enterAccount: 'Nhập tài khoản hoặc số điện thoại',
    enterPassword: 'Nhập mật khẩu',
    login: 'Đăng nhập',
    password: 'Mật khẩu',
    wrongAccount: 'Tài khoản không tồn tại',
    wrongPassword: 'Mật khẩu không đúng',
    wrongVerifyCode: 'Mã xác minh không đúng',
  },
  common: {
    accountNotExist: 'Tài khoản không tồn tại',
    add: 'Thêm',
    back: 'Quay lại',
    cancel: 'Hủy',
    close: 'Đóng',
    confirm: 'Xác nhận',
    continue: 'Tiếp tục',
    create: 'Tạo',
    done: 'Xong',
    emptyData: 'Không có dữ liệu',
    emptyResult: 'Không tìm thấy dữ liệu',
    errorConnectDb: 'Xảy ra lỗi kết nối dữ liệu, vui lòng thử lại',
    errorDb: 'Xảy ra lỗi truy vấn dữ liệu, vui lòng thử lại',
    errorGetOTP: 'Không thể lấy mã OTP, vui lòng kiểm tra số điện thoại và thử lại nhé!',
    errorSignOTP: 'Mã OTP không đúng',
    failure: 'Thất bại',
    mediaUploadFailed: 'Upload không thành công',
    next: 'Tiếp',
    optional: 'Tùy chọn',
    pickImage: 'Chọn ảnh từ thư viện',
    remove: 'Xóa',
    saveImageToLocal: 'Lưu ảnh',
    search: 'Tìm kiếm',
    success: 'Thành công',
    takePhoto: 'Chụp ảnh',
    tryAgain: 'Xảy ra lỗi, vui lòng thử lại sau',
    unauthorized: 'Bạn không có quyền thực hiện yêu cầu này',
    unsupportedFile: 'Không hỗ trợ định dạng file',
    update: 'Cập nhật',
  },
  copyright: '© 2024 We Grow - All Rights Reserved.',
  dateTime: {
    day: 'ngày',
    enterDayOfWeek: 'Thứ',
    enterEndTime: 'Kết thúc',
    enterStartTime: 'Bắt đầu',
    friday: 'Thứ 6',
    hour: 'giờ',
    justDone: 'vừa xong',
    minute: 'phút',
    monday: 'Thứ 2',
    saturday: 'Thứ 7',
    sunday: 'Chủ nhật',
    thursday: 'Thứ 5',
    tuesday: 'Thứ 3',
    wednesday: 'Thứ 4',
  },
  forum: {
    addOption: 'Thêm lựa chọn mới...',
    addPhotoVideo: 'Thêm ảnh/video',
    anonymous: 'Ẩn danh',
    anonymousComment: 'Bình luận ẩn danh',
    anonymousMother: 'Ẩn danh',
    bookmarkPostInvalidPostId: 'Id bài viết không hợp lệ',
    bookmarkPostLibrary: 'Đã lưu',
    bookmarkPostRequirePostId: 'Id bài viết là bắt buộc',
    commentInvalidContent: 'Nội dung không hợp lệ',
    commentInvalidPostId: 'Id bài viết không hợp lệ',
    commentNow: 'Chia sẻ bình luận của bạn',
    commentRequireContent: 'Nội dung là bắt buộc',
    commentRequirePostId: 'Id bài viết là bắt buộc',
    comments: 'Bình luận',
    contentPollPlaceholder: 'Hãy đặt câu hỏi....',
    createPoll: 'Tạo bình chọn',
    createPostBtn: 'Bạn đang nghĩ gì ....',
    createPostPlaceholder: 'Bạn đang nghĩ gì ....',
    deletePost: 'Xóa bài viết',
    deletePostDescription: 'Xóa bài viết này',
    editPost: 'Chỉnh sửa bài viết',
    editPostDescription: 'Chỉnh sửa nội dung bài viết',
    hasBookmark: 'Bài viết đã được thêm vào mục đã lưu',
    like: 'Thích',
    myPostLibrary: 'Của tôi',
    option: 'Lựa chọn',
    pollMulti: 'Cho phép chọn nhiều lựa chọn',
    popularTopics: 'Chủ đề phổ biến tuần này',
    postAnonymously: 'Đăng ẩn danh',
    postInvalidContent: 'Bài viết không hợp lệ',
    postInvalidIsMultiAnswer: 'Trường trả lời không hợp lệ',
    postInvalidIsPrivate: 'Trạng thái bài viết không hợp lệ',
    postInvalidPoll: 'Bình chọn không hợp lệ',
    postLibrary: 'Thư viện bài viết',
    postNow: 'Đăng bài',
    postPending: 'Đang chờ duyệt',
    postReject: 'Bài viết bị từ chối',
    postRequireContent: 'Nội dung bài viết là bắt buộc',
    postRequireIsPrivate: 'Trạng thái bài viết là bắt buộc',
    postWaitApprove: 'Bài viết của đang đang chờ duyệt',
    readMore: 'Xem thêm...',
    removeSavePost: 'Xoá khỏi danh sách lưu',
    removeSavePostDescription: 'Xoá khỏi các mục đã lưu',
    savePost: 'Lưu bài viết',
    savePostDescription: 'Thêm vào các mục đã lưu',
    searchPost: 'Tìm kiếm bài viết',
    share: 'Chia sẻ',
    tagInvalidHashTag: 'HashTag không hợp lệ',
    tagInvalidImage: 'Hình ảnh HashTag không hợp lệ',
    tagInvalidTitle: 'Tiêu đề HashTag không hợp lệ',
    tagRequireHashTag: 'HashTag là bắt buộc',
    tagRequireImage: 'Hình ảnh HashTag là bắt buộc',
    tagRequireTitle: 'Tiêu đề HashTag là bắt buộc',
    title: 'Cộng đồng',
    topic: 'bài viêt',
    voteCount: 'lượt',
  },
  handbook: {
    title: 'Cẩm nang',
  },
  learn: {
    title: 'Học tập',
  },
  profile: {
    title: 'Cá nhân',
  },
  syncUpdate: {
    appUpdate: 'Cập nhật ứng dụng',
    continue: 'Tiếp tục',
    downloadingPackage: 'Đang tải bản cập nhật...',
    ignore: 'Bỏ qua',
    install: 'Cài đặt',
    installingUpdate: 'Đang cài đặt bản cập nhật',
    mandatoryUpdateMessage: 'Một bản cập nhật cần được cài đặt:',
    optionalUpdateMessage: 'Một bản cập nhật có sẵn:',
    upToDate: 'Phiên bản hiện tại là mới nhất',
    updateInstalled: 'Cập nhật thành công',
    updateTitle: 'Có bản cập nhật mới',
  },
  teenCare: 'TeenCare',
  weGrow: 'We Grow',
}
