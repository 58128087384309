import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_TOPIC, UPDATE_TOPIC } from '~saga'

import { Topic } from '@teen-care/core'
import { useAppDispatch } from '~core'
import { useState } from 'react'

export function CreateTopic({
  content,
  item,
  title,
}: {
  content: string
  item?: Topic
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [media, setMedia] = useState<any | undefined>()
  const [hashTag, setHashTag] = useState<string>(item?.hashTag || '#')
  const [topicTitle, setTitle] = useState<string>(item?.title || '')

  const clearData = () => {
    setShowCreate(false)
    setMedia(undefined)
    setHashTag('')
    setTitle('')
  }

  const handleShow = () => {
    setShowCreate(true)
    setMedia(undefined)
    item && setHashTag(item?.hashTag || '')
    item && setTitle(item?.title || '')
  }

  function createTopic() {
    dispatch({
      onSuccess: clearData,
      payload: isUpdate
        ? { _id: item?._id, hashTag, media, title: topicTitle }
        : { hashTag, media, title: topicTitle },
      type: isUpdate ? UPDATE_TOPIC : CREATE_TOPIC,
    })
  }

  return (
    <>
      <Button className='m-1' variant='dark' onClick={handleShow}>
        {title}
      </Button>

      <Modal show={showCreate} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Tên</Form.Label>
            <Form.Control
              placeholder='Nhập tên topic'
              value={topicTitle}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Hashtag</Form.Label>
            <Form.Control
              placeholder='Nhập hashtag topic'
              value={hashTag}
              onChange={(e) => setHashTag(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
            <Form.Label className='fw-bold m-1'>Hình ảnh</Form.Label>
            <Form.Control
              accept='image/jpg, image/jpeg, image/png, image/webp, image/tiff, image/gif, image/heic'
              type='file'
              onChange={(e: any) => setMedia(e?.target?.files[0])}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={createTopic}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
