import { Button, Form, Modal } from 'react-bootstrap'
import { CREATE_USER_ROLE, UPDATE_USER_ROLE } from '~saga'
import { Course, ForumGroup, ForumTopic, UserRole } from '@teen-care/core'
import { courseState, forumGroupState, forumTopicState } from '~redux'
import { useAppDispatch, useAppSelector } from '~core'

import { uniqBy } from 'lodash'
import { useState } from 'react'

export function CreateUserRole({
  content,
  item,
  textOnly,
  title,
}: {
  content: string
  item?: UserRole
  textOnly?: boolean
  title: string
}) {
  const isUpdate = item && item?._id?.length > 0
  const dispatch = useAppDispatch()
  const { forumGroups } = useAppSelector(forumGroupState) || {}
  const { forumTopics } = useAppSelector(forumTopicState) || {}
  const { courses } = useAppSelector(courseState) || {}
  const [show, setShow] = useState<boolean>(false)
  const [userRoleName, setUserRoleName] = useState<string>(item?.userRoleName || '')
  const [forumGroupIds, setForumGroupIds] = useState<string[]>([])
  const [forumTopicIds, setForumTopicIds] = useState<string[]>([])
  const [learnCourseIds, setLearnCourseIds] = useState<
    { _id: string; read: boolean; write: boolean }[]
  >([])

  const clearData = () => {
    setShow(false)
    setUserRoleName('')
    setForumGroupIds([])
    setForumTopicIds([])
    setLearnCourseIds([])
  }

  const handleShow = () => {
    setShow(true)
    setUserRoleName(isUpdate ? item?.userRoleName || '' : '')
    setForumGroupIds(isUpdate ? item?.forumGroupIds || [] : [])
    setForumTopicIds(isUpdate ? item?.forumTopicIds || [] : [])
    setLearnCourseIds(isUpdate ? item?.learnCourseIds || [] : [])
  }

  function addUserRole() {
    dispatch({
      onSuccess: () => {
        clearData()
      },
      payload: {
        ...(isUpdate ? { _id: item?._id } : {}),
        forumGroupIds,
        forumTopicIds,
        learnCourseIds: learnCourseIds?.filter((e) => e.read || e.write),
        userRoleName,
      },
      type: isUpdate ? UPDATE_USER_ROLE : CREATE_USER_ROLE,
    })
  }

  return (
    <>
      {textOnly ? (
        <div onClick={handleShow}>{title}</div>
      ) : (
        <Button className='m-1' variant='dark' onClick={handleShow}>
          {title}
        </Button>
      )}

      <Modal fullscreen show={show} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title>{content}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Tên vai trò</Form.Label>
            <Form.Control
              placeholder='Nhập tên vai trò'
              value={userRoleName}
              onChange={(e) => setUserRoleName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Menu cộng đồng</Form.Label>
            <div className='mb-1'>{'Danh sách topic'}</div>
            {(forumTopics || []).map((item: ForumTopic) => {
              const isChecked =
                forumTopicIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
              return (
                <Form.Check
                  checked={isChecked}
                  className='mb-1'
                  key={item?._id}
                  label={`${item?.title} ${item?.hashTag}`}
                  onChange={(e: any) => {
                    setForumTopicIds((current) =>
                      e?.target?.checked
                        ? [item?._id, ...current]
                        : current?.filter((e) => e !== item?._id),
                    )
                  }}
                />
              )
            })}

            <div className='mb-1 mt-3'>{'Danh sách nhóm'}</div>
            {(forumGroups || []).map((item: ForumGroup) => {
              const isChecked =
                forumGroupIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
              return (
                <Form.Check
                  checked={isChecked}
                  className='mb-1'
                  key={item?._id}
                  label={item?.groupName}
                  onChange={(e: any) => {
                    setForumGroupIds((current) =>
                      e?.target?.checked
                        ? [item?._id, ...current]
                        : current?.filter((e) => e !== item?._id),
                    )
                  }}
                />
              )
            })}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label className='fw-bold mb-1'>Menu khóa học</Form.Label>
            <div className='mb-1'>{'Danh sách khóa học'}</div>
            {(courses || []).map((item: Course) => {
              const indexChecked = learnCourseIds?.findIndex(
                (e) => e?._id?.toString() === item?._id?.toString(),
              )
              const isReadChecked = indexChecked !== -1 && learnCourseIds?.[indexChecked]?.read
              const isWriteChecked = indexChecked !== -1 && learnCourseIds?.[indexChecked]?.write
              return (
                <div className='d-flex mb-1' key={item?._id}>
                  <Form.Check
                    checked={isReadChecked}
                    label='Xem'
                    onChange={(e: any) => {
                      setLearnCourseIds((current) => {
                        const index = current?.findIndex(
                          (e) => e?._id?.toString() === item?._id?.toString(),
                        )
                        const checked = e?.target?.checked
                        return uniqBy(
                          [
                            {
                              _id: item?._id,
                              read: checked,
                              write: !checked ? false : !!current?.[index]?.write,
                            },
                            ...current,
                          ],
                          '_id',
                        )
                      })
                    }}
                  />
                  <Form.Check
                    checked={isWriteChecked}
                    className='m-2 mt-0 mb-0'
                    label='Tham gia'
                    onChange={(e: any) => {
                      setLearnCourseIds((current) => {
                        const index = current?.findIndex(
                          (e) => e?._id?.toString() === item?._id?.toString(),
                        )
                        const checked = e?.target?.checked
                        return uniqBy(
                          [
                            {
                              _id: item?._id,
                              read: checked || !!current?.[index]?.read,
                              write: checked,
                            },
                            ...current,
                          ],
                          '_id',
                        )
                      })
                    }}
                  />
                  - {item?.courseName}
                </div>
              )
            })}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={clearData}>
            Hủy
          </Button>
          <Button variant='dark' onClick={addUserRole}>
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
