import { Card, Form, Table } from 'react-bootstrap'
import { CreateTopic, TopicItem } from './components'
import { GET_FORUM_TOPICS, LOAD_MORE_FORUM_TOPICS } from '~saga'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'

import { Paging } from '~components'
import { debounce } from 'lodash'
import { forumTopicState } from '~redux'

export function ForumTopic() {
  const dispatch = useAppDispatch()
  const { currentPage, forumTopics, totalPages } = useAppSelector(forumTopicState) || {}
  const [search, setSearch] = useState<string>('')
  const [isFetched, setFetched] = useState<boolean>(false)

  useEffect(() => {
    getTopics()
  }, [])

  useEffect(() => {
    const debouncedGetPosts = debounce(getTopics, 1000)
    debouncedGetPosts()
    return () => {
      debouncedGetPosts.cancel()
    }
  }, [search])

  function getTopics() {
    dispatch({
      onSuccess: () => setFetched(true),
      payload: { search },
      type: GET_FORUM_TOPICS,
    })
  }

  function onChangePage(page: number) {
    isFetched && dispatch({ payload: { page, search }, type: LOAD_MORE_FORUM_TOPICS })
  }

  return (
    <div className='container-fluid p-3'>
      <Form.Label className='fw-bold'>Danh sách Topic</Form.Label>
      <Form.Group className='d-flex align-items-center justify-content-between mb-2'>
        <Form.Control
          className='w-75'
          placeholder='Tìm theo tên, hashtag'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CreateTopic content='Thêm Topic' title='Thêm Topic' />
      </Form.Group>
      <Card className='table-wrapper table-responsive shadow-sm mb-5'>
        <Card.Body className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                {[
                  '#',
                  'Tên Topic',
                  'Hashtag',
                  'Hình ảnh',
                  'Thời gian cập nhật',
                  'Người cập nhật',
                  'Thao tác',
                ].map((item, index) => (
                  <th className='border-bottom' key={`${index}`}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {forumTopics.map((item, index) => (
                <TopicItem index={index} item={item} key={`${index}`} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-end'>
            <Paging currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage} />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  )
}
