import 'swiper/css'

import { Swiper, SwiperSlide } from 'swiper/react'

import { getImage } from '~core'

export function Slider({ image = [] }: { image?: Array<string> }) {
  return (
    <Swiper autoplay loop>
      {image.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <img alt={image} className='img-fluid mb-3' src={getImage(image)} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
