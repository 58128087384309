import { all, fork } from 'redux-saga/effects'
import {
  commentSaga,
  postHistorySaga,
  postSaga,
  topicSaga,
  userBadgeSaga,
  userProfileSaga,
  userRankingSaga,
  userSaga,
  userScoreSaga,
} from '~saga'

export function* rootSaga() {
  yield all([
    fork(commentSaga),
    fork(postSaga),
    fork(postHistorySaga),
    fork(userBadgeSaga),
    fork(userProfileSaga),
    fork(topicSaga),
    fork(userRankingSaga),
    fork(userSaga),
    fork(userScoreSaga),
  ])
}
